import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Platform} from '@angular/cdk/platform';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {DocumentsService} from '../services/documents/documents.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CordovaService} from '../cordova.service';
import {Utils} from '../utils.class';
import {Settings} from '../settings.class';
import {PdfViewerDialogComponent} from './pdf-viewer-dialog/pdf-viewer-dialog.component';
import {Storage} from '../storage.class';
import {RenameDocumentComponent} from './rename-chapter/rename-document.component';
import {AddDocumentComponent} from './add-chapter/add-document.component';
import {Workarea} from '../workarea.class';
import {Document} from '../document';
import {WorkareaService} from '../services/workarea/workarea.service';
import {ConfirmModalService} from '../shared/confirm-modal.service';
import {Status} from '../status.class';
import {EditDocumentDetailsComponent} from './edit-document-details/edit-document-details.component';
import {first} from 'rxjs/operators';
import {CameraService} from '../camera.service';
import {ImageViewerDialogComponent} from './image-viewer-dialog/image-viewer-dialog.component';
import {Routenames} from '../route-names.enum';

declare var cordova;

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {

    statuses: Array<Status> = [];
    workarea: Workarea;
    loaded = false;

    public isAdmin = false;

    public showContextMenu: boolean;
    public parentItem: Document;
    public selectedFolders: Map<Document, Document> = new Map<Document, Document>();
    public breadcrumb: Document[];
    public Utils = Utils;
    public Settings = Settings;
    public Storage = Storage;
    Routenames = Routenames;
    public icons = {
        'bmp': 'fa-file-image',
        'gif': 'fa-file-image',
        'jpg': 'fa-file-image',
        'png': 'fa-file-image',
        'image/png': 'fa-file-image',
        'image/jpeg': 'fa-file-image',
        'ods': 'fa-file-excel',
        'xls': 'fa-file-excel',
        'xlsx': 'fa-file-excel',
        'doc': 'fa-file-word',
        'docx': 'fa-file-word',
        'zip': 'fa-file-archive',
        '': 'fa-file',
        null: 'fa-file',
        'undefined': 'fa-file',
        'pdf': 'fa-file-pdf',
        'application/pdf': 'fa-file-pdf',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'fa-file-excel',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'fa-file-word',
        'application/x-zip-compressed': 'fa-file-archive'
    };
    public extHasThumb: Map<string, boolean> = new Map<string, boolean>([
        ['jpg', true],
        ['jpeg', true],
        ['png', true]
    ]);
    public isCordova = false;
    private isReserved = false;
    private subscriptions = new Subscription();

    constructor(public platform: Platform,
                private route: ActivatedRoute,
                private confirmDialogService: ConfirmDialogService,
                private confirmModalService: ConfirmModalService,
                private libraryService: DocumentsService,
                private workareaService: WorkareaService,
                private dialog: MatDialog,
                private cameraService: CameraService,
                private router: Router) {
    }

    private _viewingFolder: Document[];

    get viewingFolder(): any {
        return this._viewingFolder;
    }

    set viewingFolder(value: any) {
        const sortingOn = 'name';
        this._viewingFolder = value ? value.sort((a, b) => {
            if (a[sortingOn].toUpperCase() < b[sortingOn].toUpperCase()) {
                return -1;
            }
            if (a[sortingOn].toUpperCase() > b[sortingOn].toUpperCase()) {
                return 1;
            }
            return 0;
        }).sort((a, b) => {
            if (!b.file && a.file) {
                return 1;
            }
            if (b.file && !a.file) {
                return -1;
            }
            return 0;
        }) : value;
    }

    @HostListener('dragover', ['$event'])
    public ondragover(evt) {
        evt.preventDefault();
        evt.stopPropagation();

    }

    @HostListener('dragleave', ['$event'])
    public ondragleave(evt) {
        evt.preventDefault();
        evt.stopPropagation();

    }

    @HostListener('drop', ['$event'])
    public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        const files = evt.dataTransfer.files;
        this.addNewItemDialog(this.parentItem, files);
    }

    ngOnInit() {
        this.isCordova = typeof cordova !== 'undefined';
        CordovaService.setBackbuttonActionUrl('/workareas');
        this.isAdmin = Utils.userHasRights('ADMIN');

        this.subscriptions.add(this.route.params.pipe(first()).subscribe(params => {
            this.loaded = false;
            const id = +params['id'];
            if (isFinite(id) && id > 0) {
                this.loadWorkarea(id);
            } else {
                this.workarea = new Workarea();
                this.workarea.lat = Settings.mapCenter.latitude;
                this.workarea.lng = Settings.mapCenter.longitude;
                this.workarea.has_documents = true;
                this.loaded = true;
                this.workareaService.reserve(this.workarea).then(workarea => {
                    this.workarea = workarea;
                    this.workarea.documents = [];
                    this.viewingFolder = this.workarea.documents;
                    const dialogref = this.dialog.open(EditDocumentDetailsComponent, {
                        panelClass: 'document-dialog',
                        data: this.workarea
                    });
                    dialogref.afterClosed().pipe(first()).subscribe(() => {
                        if (!this.workarea.street) {
                            this.workareaService.removeReservation(this.workarea);
                            this.router.navigate(['towns', workarea.town_id]);
                        } else {
                            this.router.navigate(['towns', workarea.town_id, 'workareas', this.workarea.id, 'documents']);
                        }

                    });
                    this.isReserved = true;
                });
            }
        }));
    }

    outsideClick(event) {
        if (['folder-view', 'details-container', 'folders'].indexOf(event.target.className) !== -1) {
            this.showContextMenu = false;
            this.selectedFolders.clear();
        }
    }

    select(event, doc: Document) {
        if ((this.platform.IOS || this.platform.ANDROID) && event.type !== 'contextmenu' && event.type !== 'press') {
            this.open(doc);
        } else {
            if (event.ctrlKey || event.shiftKey) {
                this.selectedFolders.has(doc) ? this.selectedFolders.delete(doc) : this.selectedFolders.set(doc, doc);
            } else if (event.type === 'contextmenu' || event.type === 'press') {
                if (this.selectedFolders.size === 0) {
                    this.selectedFolders.set(doc, doc);
                } else {
                    if (!this.selectedFolders.has(doc)) {
                        this.selectedFolders.clear();
                        this.selectedFolders.set(doc, doc);
                    }
                }
            } else {
                this.showContextMenu = false;
                this.selectedFolders.clear();
                this.selectedFolders.set(doc, doc);
            }
        }

    }

    loadWorkarea(id) {
        this.loaded = false;
        this.libraryService.getDocumentsForWorkarea(id).then((workarea: Workarea) => {
            this.workarea = workarea;
            if (!this.viewingFolder || !this.parentItem) {
                this.viewingFolder = this.workarea.documents;
            } else {
                const flatTree = this.flatDocumentTree(this.workarea.documents);
                if (this.parentItem) {
                    const item = flatTree.find(f => f.id == this.parentItem.id);
                    this.viewingFolder = item.sub_items;
                    this.parentItem = item;
                }
            }
            this.loaded = true;
        }, error => {
            this.loaded = true;
        });
    }

    reload() {
        this.loadWorkarea(this.workarea.id);
    }


    editDocumentDetails() {
        this.dialog.open(EditDocumentDetailsComponent, {
            panelClass: 'document-dialog',
            data: this.workarea
        });
    }

    createBreadcrumb() {
        const breadcrumb = [];
        if (this.parentItem) {
            breadcrumb.push(this.parentItem);
            const flatTree = this.flatDocumentTree(this.workarea.documents);
            const getParent = (d: Document) => {
                return flatTree.find(f => f.id == d.parent);
            };
            let item = getParent(this.parentItem);
            while (typeof item !== 'undefined') {
                breadcrumb.push(item);
                item = getParent(item);
            }
            this.breadcrumb = breadcrumb.reverse();
        } else {
            this.breadcrumb = [];
        }
    }

    open(docu: Document = null, toHome = false) {
        if (!toHome && !docu && this.selectedFolders.size === 1) {
            docu = Array.from(this.selectedFolders.keys())[0];
        }
        if (!docu) {
            this.parentItem = null;
            this.viewingFolder = this.workarea.documents;
        } else {
            this.showContextMenu = false;
            if (docu.file) {
                this.openDocument(docu);
            } else {
                this.parentItem = docu;
                this.viewingFolder = docu.sub_items;
            }
        }
        this.selectedFolders.clear();
        this.createBreadcrumb();
    }

    up() {
        const flatTree = this.flatDocumentTree(this.workarea.documents);
        if (this.parentItem) {
            const item = flatTree.find(f => f.id == this.parentItem.parent);
            this.parentItem = typeof item === 'undefined' ? null : item;
            this.viewingFolder = typeof item === 'undefined' ? this.workarea.documents : item.sub_items;
        } else {
            this.viewingFolder = this.workarea.documents;
            this.parentItem = null;
        }
        this.createBreadcrumb();
    }

    download(document: Document = null) {
        if (!document && this.selectedFolders.size === 1) {
            document = Array.from(this.selectedFolders.keys())[0];
        }
        window.open(`${Settings.API_ENDPOINT}documents/download/${document.id}/download?access_token=${Storage.getUserToken()}`);
    }

    openDocument(document: Document) {
        const url = `${Settings.API_ENDPOINT}documents/download/${document.id}?access_token=${Storage.getUserToken()}`;
        if (['pdf', 'xls', 'xlsx'].indexOf(document.ext) !== -1) {
            if (this.platform.IOS) {
                cordova.InAppBrowser.open(
                    url,
                    '_blank',
                    'location=yes,closebuttoncaption=Sluiten,hidenavigationbuttons=yes,enableViewportScale=yes,closebuttoncolor=#e30613');
            } else if (this.platform.ANDROID) {
                if (['pdf'].indexOf(document.ext) !== -1) {
                    cordova.InAppBrowser.open(
                        `https://docs.google.com/gview?embedded=true&url=${url}`,
                        '_blank',
                        'location=yes,closebuttoncaption=Sluiten,hidenavigationbuttons=yes,closebuttoncolor=#e30613,hideurlbar=yes');
                } else {
                    cordova.InAppBrowser.open(
                        url,
                        '_system',
                        'location=yes,closebuttoncaption=Sluiten,hidenavigationbuttons=yes,closebuttoncolor=#e30613,hideurlbar=yes');
                }
            } else {
                if (['pdf'].indexOf(document.ext) !== -1) {
                    const dialogRef = this.dialog.open(PdfViewerDialogComponent, {
                        width: '99%',
                        maxWidth: '99%',
                        maxHeight: '99%',
                        height: '99%',
                        data: {pdf: url, name: document.name}
                    });
                } else {
                    window.open(`${Settings.API_ENDPOINT}documents/download/${document.id}/download?access_token=${Storage.getUserToken()}`);
                }
            }
        } else if (['jpg', 'jpeg', 'png', 'gif'].indexOf(document.ext) !== -1) {
            const dialogRef = this.dialog.open(ImageViewerDialogComponent, {
                panelClass: 'image-viewer-dialog',
                data: {
                    documentImages: this.viewingFolder.filter(f => ['jpg', 'jpeg', 'png', 'gif'].indexOf(f.ext) !== -1),
                    viewIndex: this.viewingFolder.filter(f => ['jpg', 'jpeg', 'png', 'gif'].indexOf(f.ext) !== -1).indexOf(document)
                }
            });
        } else {
            window.open(url);
        }
    }

    deleteItem(event, docu: Document = null, parentChapter: Document = null) {
        let documents = [];
        if (this.selectedFolders.size === 1) {
            docu = Array.from(this.selectedFolders.keys())[0];
            documents.push(docu);
        } else if (this.selectedFolders.size > 1) {
            documents = Array.from(this.selectedFolders.keys());
        }
        if (documents && documents.length > 0) {

            this.showContextMenu = false;
            event.stopPropagation();
            if (documents.length === 1) {
                const messageType = docu.file ? 'Bestand' : 'Map';
                const messageSingular = (docu.sub_items && docu.sub_items.length) === 1 ? `met 1 onderliggend item` : '';
                const messagePlural = (docu.sub_items && docu.sub_items.length) > 1 ? `met ${docu.sub_items.length} onderliggende items` : messageSingular;
                const messageSubItems = docu.sub_items ? `<b>${messagePlural}</b>` : '';
                this.deleteItems(messageType, docu, messageSubItems, parentChapter);
            } else if (documents.length > 1) {
                const messageType = 'Items';
                const messageSubItems = 'Weet u zeker dat u alle geselecteerde items met eventuele onderliddende wilt verwijderen?';
                this.deleteItems(messageType, documents, messageSubItems, parentChapter);
            }
        }
    }

    contextMenuItem(event, contextMenu) {
        this.showContextMenu = true;
        //event.stopPropagation();
        event.preventDefault();
        if (event.type === 'press') {
            contextMenu.setAttribute('style', `top: ${event.center.y}px; left: ${event.center.x}px`);
        } else {
            contextMenu.setAttribute('style', `top: ${event.clientY}px; left: ${event.clientX}px`);
        }
    }

    renameItem(event, docu: Document = null) {
        if (this.selectedFolders.size === 1) {
            docu = Array.from(this.selectedFolders.keys())[0];
        }
        if (docu) {
            this.showContextMenu = false;
            event.stopPropagation();
            const dialogRef = this.dialog.open(RenameDocumentComponent, {
                width: '500px',
                maxHeight: '600px',
                data: docu
            });
        }
    }

    camera() {
        this.cameraService.getPicture().then((data: string) => {
            const newChapter = new Document();
            newChapter.parent = this.parentItem ? this.parentItem.id : null;
            newChapter.file = data;
            newChapter.orginal_file = data['file'];
            newChapter.ext = data['ext'];
            newChapter.filesize = data['filesize'];
            newChapter.workarea_id = this.workarea.id;
            this.libraryService.saveCameraImage(newChapter).then(chapter => {
                chapter.sub_items = [];
                if (this.parentItem) {
                    this.parentItem.sub_items.push(chapter);
                } else {
                    this.workarea.documents.push(chapter);
                }
            }, error => {

            });
        });

    }

    addNewItem(event, parentChapter: Document = null, isFolder = false) {
        event.stopPropagation();
        this.addNewItemDialog(parentChapter, null, isFolder);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private addNewItemDialog(parentChapter: Document, files: File[] = null, isFolder = false) {
        if (parentChapter) {
            parentChapter['expanded'] = true;
        }
        const dialogRef = this.dialog.open(AddDocumentComponent, {
            width: '500px',
            maxHeight: '600px',
            data: {
                parentChapter,
                workarea: this.workarea,
                files,
                isFolder
            }
        });
        const subs = dialogRef.afterClosed().subscribe((chapterS) => {
            this.reload();
        });
    }

    private deleteItems(messageType: string, docu: Document | Document[], messageSubItems: string, parentChapter: Document) {
        const docName = Array.isArray(docu) ? '' : `<u>${docu.name}</u>`;
        const docIds = Array.isArray(docu) ? docu.map(d => d.id) : [docu.id];
        this.confirmDialogService.confirm(
            `${messageType} verwijderen`,
            `Weet u zeker dat u ${messageType} ${docName} ${messageSubItems} wilt verwijderen?`,
            'Verwijderen',
            'Annuleren').then(() => {
            this.libraryService.delete(docIds).then(result => {
                this.reload();
            }, () => {
                this.reload();
                this.confirmDialogService.confirm(
                    'Fout bij verwijderen',
                    `Er is iets fout gegaan bij het verwijderen`,
                    'Oké', null);
            });
        }, () => {

        });
    }

    private flatDocumentTree(arr): Document[] {
        return arr.reduce((flat, toFlatten) => {
            return flat.concat(!toFlatten.file ? this.flatDocumentTree(toFlatten.sub_items).concat(toFlatten) : toFlatten);
        }, []);
    }
}
