import {Storage} from './storage.class';
import {debounceTime, take} from 'rxjs/operators';
import {AbstractControl, FormArray, FormGroup} from '@angular/forms';
import {fromEvent} from 'rxjs';
import {formatNumber} from '@angular/common';

export class Utils {

    constructor() {

    }

    static isIOS() {
        if (/iPad|iPhone|iPod/.test(navigator.platform)) {
            return true;
        } else {
            return navigator.maxTouchPoints &&
                navigator.maxTouchPoints > 2 &&
                /MacIntel/.test(navigator.platform);
        }
    }

    static isAndroid() {
        if (/Android/.test(navigator.userAgent)) {
            return true;
        }
        return false;
    }

    public static isLocalHost() {
        return (location.hostname === 'localhost' || location.hostname === '127.0.0.1');
    }

    public static timestamp() {
        return Date.now();
    }

    static compare(a: number | string, b: number | string, isAsc: boolean) {
        a = ('' + a).trim().toLowerCase().replace(/[^a-zA-Z0-9]/, '');
        b = ('' + b).trim().toLowerCase().replace(/[^a-zA-Z0-9]/, '');
        if (!a.length) {
            a = isAsc ? 'zzzz' : 'aaaa';
        }
        if (!b.length) {
            b = isAsc ? 'zzzz' : 'aaaa';
        }
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    public static userIsGroup(group) {
        let user = Storage.getUser();

        const groups = [group];

        if (group === 'READ') {
            groups.push('EMPLOYEE_READ');
        }

        return groups.includes(user?.group);
    }

    static triggerValidationP(control: AbstractControl, source: AbstractControl[] = []) {
        return new Promise<void>((resolve, reject) => {
            const sub = control.statusChanges.pipe(debounceTime(5)).subscribe(status => {
                if (status === 'VALID') {
                    resolve();
                    sub.unsubscribe();
                }
                if (status === 'INVALID') {
                    reject();
                    sub.unsubscribe();
                }
            });

            Utils.triggerValidation(control, source);
        });
    }

    static triggerValidation(control: AbstractControl, source: AbstractControl[] = []) {
        if (control instanceof FormGroup) {
            const group = (control as FormGroup);

            for (const field in group.controls) {
                const c = group.controls[field];

                this.triggerValidation(c, source);
            }
        } else if (control instanceof FormArray) {
            const group = (control as FormArray);
            group.markAsDirty();

            for (const field in group.controls) {
                const c = group.controls[field];

                this.triggerValidation(c, source);
            }
        } else {
            control.markAllAsTouched();
            control.updateValueAndValidity({emitEvent: source.indexOf(control) === -1});
        }
        if (source.length === 0 && !control.valid && control instanceof FormGroup) {
            const firstInvalidControl: HTMLElement = window.document.querySelector('form .ng-invalid');
            if (firstInvalidControl) {
                firstInvalidControl.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'start'});

                fromEvent(window, 'scroll')
                    .pipe(debounceTime(100), take(1))
                    .subscribe(() => {
                        const input = firstInvalidControl.querySelector('input');
                        if (input) {
                            input.focus();
                        }
                    });
            }
        }
    }

    public static userHasRights(group) {
        let user = Storage.getUser();
        if (!user) {
            return false;
        }

        if (group === 'USER') {
            if ([group, 'READ', 'GEMEENTE', 'SUBADMIN', 'ADMIN'].indexOf(user.group) >= 0) {
                return true;
            }
        }
        if (group === 'ADMIN') {
            if ([group, 'ADMIN'].indexOf(user.group) >= 0) {
                return true;
            }
        }
        // Gemeente is not allowed for READ AND CONSUMER
        if (group === 'GEMEENTE') {
            if ([group, 'SUBADMIN', 'ADMIN'].indexOf(user.group) >= 0) {
                return true;
            }
        }
        // Consumer is not allowed for READ
        if (group === 'CONSUMER') {
            if ([group, 'GEMEENTE', 'SUBADMIN', 'ADMIN'].indexOf(user.group) >= 0) {
                return true;
            }
        }
        if (group === 'READ') {
            if ([group, 'GEMEENTE', 'SUBADMIN', 'ADMIN', 'EMPLOYEE_READ'].indexOf(user.group) >= 0) {
                return true;
            }
        }
        if (group === 'EMPLOYEE_READ') {
            if ([group, 'GEMEENTE', 'SUBADMIN', 'ADMIN'].indexOf(user.group) >= 0) {
                return true;
            }
        }
        if (group === 'CONTRACTOR') {
            if ([group, 'GEMEENTE', 'SUBADMIN', 'ADMIN'].indexOf(user.group) >= 0) {
                return true;
            }
        }
        if (group === 'SUBADMIN') {
            if ([group, 'ADMIN'].indexOf(user.group) >= 0) {
                return true;
            }
        }
        if ([group, 'SUBADMIN', 'ADMIN'].indexOf(user.group) >= 0) {
            return true;
        }
        return false;
    }


    public static multi(objectName: string, object) {

        if (object != null && object != undefined) {
            if (typeof object[objectName] == "undefined") {
                object[objectName] = [];
            }
            object[objectName].push({name: ""});
        }

    }

    public static isLastInList(item, list) {
        var index = list.indexOf(item);
        if (index == list.length - 1)
            return true;
        return false;
    }

    public static isFirstInList(item, list) {
        var index = list.indexOf(item);
        if (index == 0)
            return true;
        return false;
    }

    public static removePage(item, list) {
        let index = list.indexOf(item);
        this.removePageOnIndex(list, index);
    }

    public static removePageOnIndex(list, index) {
        list.splice(index, 1);
    }

    public static upPage(item, list) {
        var index = list.indexOf(item);
        list.splice(index - 1, 0, list.splice(index, 1)[0]);
    }

    public static downPage(item, list) {
        var index = list.indexOf(item);
        list.splice(index + 1, 0, list.splice(index, 1)[0]);
    }


    public static variableIsPresent(variable: any) {
        if (typeof variable === 'undefined' || variable == null) {
            return false;
        }

        return variable.length > 0;
    }

    public static isToday(date: Date) {
        const testDate = new Date(date);
        const today = new Date();
        return testDate.getDate() === today.getDate() &&
            testDate.getMonth() === today.getMonth() &&
            testDate.getFullYear() === today.getFullYear();
    }

    static shortNumber(transformNumber: number, withDecimals = false) {
        if (isNaN(transformNumber)) return null; // will only work value is a number
        if (transformNumber === null) return null;
        if (transformNumber === 0) return 0;
        let abs = Math.abs(transformNumber);
        const rounder = Math.pow(10, 1);
        const isNegative = transformNumber < 0; // will also work for Negetive numbers
        let key = '';

        const powers = [
            {key: 'Q', value: Math.pow(10, 15)},
            {key: 'T', value: Math.pow(10, 12)},
            {key: 'B', value: Math.pow(10, 9)},
            {key: 'M', value: Math.pow(10, 6)},
            {key: 'K', value: 1000}
        ];

        for (let i = 0; i < powers.length; i++) {
            let reduced = abs / powers[i].value;
            reduced = Math.round(reduced * rounder) / rounder;
            if (reduced >= 1) {
                abs = reduced;
                key = powers[i].key;
                break;
            }
        }
        return (isNegative ? '-' : '') + formatNumber(abs, 'nl', withDecimals ? '0.0-3' : '0.0') + key;
    }

    static parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}
