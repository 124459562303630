import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TownProjectPhaseActivity} from '../../town-project-phase-activity';
import {TownProjectPhase} from '../../town-project-phase';
import {MatDialog} from '@angular/material/dialog';
import {TownProjectPhaseActivityEditDialogComponent} from './town-project-phase-activity-edit-dialog/town-project-phase-activity-edit-dialog.component';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {TownProjectPhaseActivityService} from '../../services/townproject/town-project-phase-activity.service';
import {CodaltComponent} from '../../codalt.component';
import {TownProjectPhaseReply} from '../../town-project-phase-reply';
import {DndDropEvent, DropEffect} from 'ngx-drag-drop';
import {BreakpointObserver} from '@angular/cdk/layout';

declare var cordova;

@Component({
    selector: 'app-town-project-phase-activities',
    templateUrl: './town-project-phase-activities.component.html',
    styleUrls: ['./town-project-phase-activities.component.scss']
})
export class TownProjectPhaseActivitiesComponent extends CodaltComponent implements OnInit, OnChanges {

    @Input()
    phase: TownProjectPhase;

    @Input()
    projectReplies: TownProjectPhaseReply[];

    @Input()
    activities: TownProjectPhaseActivity[];

    activitiesView: TownProjectPhaseActivity[];

    @Input()
    selectedActivity: TownProjectPhaseActivity;

    @Output()
    activitySelected = new EventEmitter<TownProjectPhaseActivity>();

    @Output()
    replyDropped = new EventEmitter<TownProjectPhaseReply>();

    disableDragDrop = typeof cordova !== 'undefined';

    constructor(private dialog: MatDialog,
                private breakpointObserver: BreakpointObserver,
                private townProjectPhaseActivityService: TownProjectPhaseActivityService) {
        super();
    }

    onDragged(item: any, list: any[], effect: DropEffect) {
        if (effect === "move") {
            const index = list.indexOf(item);
            list.splice(index, 1);
        }
        this.townProjectPhaseActivityService.saveOrder(this.activities).subscribe();
    }

    onDrop(event: DndDropEvent, list?: any[]) {
        if (list
            && (event.dropEffect === "copy"
                || event.dropEffect === "move")) {

            let index = event.index;
            if (typeof index === "undefined") {
                index = list.length;
            }

            list.splice(index, 0, event.data);
        }
    }

    selectActivity(activity: TownProjectPhaseActivity) {
        this.selectedActivity = activity;
        this.activitySelected.emit(activity);
    }

    drop(event, activity: TownProjectPhaseActivity) {
        const reply = event.item.data as TownProjectPhaseReply;
        reply.townprojectphase_activity_id = activity.id;
        this.replyDropped.emit(reply);
    }

    ngOnInit(): void {
        this.initView();
    }

    execute(event: MatCheckboxChange, activity: TownProjectPhaseActivity) {
        activity.executed = event.checked ? new Date() : null;
        this.townProjectPhaseActivityService.save(activity).subscribe(result => {
            Object.assign(activity, result.data);
        });
    }

    edit(phaseActivity?: TownProjectPhaseActivity) {
        if (!phaseActivity) {
            phaseActivity = new TownProjectPhaseActivity();
            phaseActivity.townprojectphase_id = this.phase.id;
            if (this.activities?.length > 0) {
                phaseActivity.order = this.activities.length + 1;
            } else {
                phaseActivity.order = 1;
            }
        }
        this.dialog.open(TownProjectPhaseActivityEditDialogComponent, {
            panelClass: 'confirm-dialog',
            disableClose: true,
            data: phaseActivity
        }).afterClosed().subscribe((phase: TownProjectPhaseActivity) => {
            if (!phase.deleted_at) {
                if (!this.activities?.find(p => p.id === phase.id) && phase) {
                    this.activities.push(phase);
                    this.initView();
                }
            } else {
                this.activities.splice(this.activities.indexOf(phase), 1);
                this.initView();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initView();
    }

    private initView() {
        if (this.activities) {
            const activities = this.activities.sort((a, b) => {
                if (a.order > b.order) {
                    return 1;
                }
                if (a.order < b.order) {
                    return -1;
                }
                return 0;
            });
            this.activitiesView = activities;
        }
    }

}
