<div class="container bg-white mt-2 item-edit mb-5" *ngIf="typeObj">
    <div>
        <h2 class="news__title">Resultaten vragenlijst</h2>
        <h4 class="text-capitalize">{{typeName}}: {{typeObj?.title}}</h4>
    </div>

    <p *ngIf="answers?.length === 0">Deze vragenlijst is nog door niemand beantwoord.</p>

    <div *ngIf="typeObj.survey" class="mt-5">
        <h4>
            Resultaten
        </h4>
        <div *ngIf="questionGraphs">
            <div *ngFor="let graph of questionGraphs">
                <h6>
                    {{graph.question}}
                </h6>
                <ngx-charts-area-chart *ngIf="graph.areaData"
                                       [view]="view"
                                       [scheme]="colorScheme"
                                       [legend]="false"
                                       [showXAxisLabel]="true"
                                       [showYAxisLabel]="true"
                                       [xAxis]="true"
                                       [yAxis]="true"
                                       xAxisLabel="Score"
                                       yAxisLabel="Aantal"
                                       [tooltipDisabled]="true"
                                       [timeline]="false"
                                       [xAxisTicks]="graph.xAxisTicks"
                                       [yAxisTicks]="graph.yAxisTicks"
                                       [yAxisTickFormatting]="shortNumberPipe.transform"
                                       [results]="graph.areaData">
                </ngx-charts-area-chart>
                <ngx-charts-pie-chart *ngIf="graph.pieData"
                                      [view]="view"
                                      [scheme]="colorScheme"
                                      [results]="graph.pieData"
                                      [gradient]="false"
                                      [legend]="false"
                                      legendPosition="right"
                                      [labels]="true"
                                      [doughnut]="false">
                </ngx-charts-pie-chart>
                <ngx-charts-bar-vertical *ngIf="graph.barData"
                                         [view]="view"
                                         [scheme]="colorScheme"
                                         [results]="graph.barData"
                                         [gradient]="false"
                                         [xAxis]="true"
                                         [yAxis]="true"
                                         [legend]="false"
                                         [showXAxisLabel]="false"
                                         [showYAxisLabel]="true"
                                         [xAxisLabel]="null"
                                         [yAxisTicks]="graph.yAxisTicks"
                                         [yAxisTickFormatting]="shortNumberPipe.transform"
                                         yAxisLabel="Aantal">
                </ngx-charts-bar-vertical>
            </div>
        </div>

        <div class="mt-5">
            <h4>
                Antwoorden op vragenlijst
            </h4>
            <div *ngFor="let question of typeObj.survey.questions">
                <h6 class="mt-3">
                    {{question.question}}
                </h6>
                <ng-container *ngIf="question.type === 'open' || question.type === 'score'">
                    <div *ngFor="let answer of answers">
                        {{answer.user.name}}: {{answer.answers[question.hash]}}
                    </div>
                </ng-container>
                <ng-container *ngIf="question.type === 'options'">
                    <div *ngFor="let option of question.options" class="answer">
                        {{option}}: {{question.answers ? question.answers[option]?.join(', ') : ''}}
                    </div>
                </ng-container>
                <ng-container *ngIf="question.type === 'boolean'">
                    <div class="answer">Ja
                        <ng-container *ngIf="question.limitCount">({{question.totalCount}} / {{question.limitCount}})</ng-container>
                        : {{question.answers ? question.answers['Ja']?.join(', ') : ''}}
                    </div>
                    <div class="answer">
                        Nee: {{question.answers ? question.answers['Nee']?.join(', ') : ''}}
                    </div>
                </ng-container>
                <ng-container *ngIf="question.type === 'boolean-quantity'">
                    <div class="mb-2 answer"><b>Ja ({{question.totalCount}} / {{question.limitCount}})</b></div>
                    <div>
                        <table *ngIf="question.answers" class="table user-count">
                            <tr>
                                <th>
                                    Naam
                                </th>
                                <th>
                                    Aantal
                                </th>
                            </tr>
                            <tr *ngFor="let answer of question.answers['Ja']">
                                <td>
                                    {{answer?.name}}
                                </td>
                                <td>
                                    {{answer?.quantity}}
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="p-1 mt-5 answer"><b>Nee:</b>
                        {{question.answers ? question.answers['Nee']?.join(', ') : ''}}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <button mat-flat-button color="primary" class="mt-5" routerLink="/{{typeName}}/{{typeObj.slug}}">
        Terug naar {{typeName}}
    </button>
</div>
<div class="floating-buttons" *ngIf="typeObj">
    <button (click)="delete()" color="primary" mat-fab>
        <mat-icon class="fas fa-trash"></mat-icon>
    </button>
    <button color="primary" mat-fab routerLink="/{{typeName}}/{{typeObj.slug}}/vragenlijst-bewerken">
        <mat-icon class="fas fa-pencil-alt"></mat-icon>
    </button>
</div>
