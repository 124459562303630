import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

declare var tinymce: any;
declare var thisEditor: any;

@Component({
    selector: 'simple-tiny',
    template: `
        <div id="{{elementId}}{{propertyName}}"></div>`
})
export class SimpleTinyComponent implements AfterViewInit, OnDestroy, OnChanges {

    @Input() elementId: String;
    @Input() content: any;
    @Input() propertyName = 'content';
    @Input() colorSetName = 'common';
    @Output() onEditorKeyup = new EventEmitter<any>();
    editor;
    private colorSet = {
        common: [
            "78BC6B", "oranje",
            "b5d56a", "groen",
            "ea7066", "rood",
            "84bed6", "groen",
            "a597e7", "paars",
            "ea77ad", "roze",
            "000000", "zwart",
            "666666", "grijs",
            "ffffff", "wit"
        ]
    };
    private currentColorSet = this.colorSet.common;

    updateview(editor) {
        const editorcontent = editor.getContent({format: 'html'}).trim();
        if (this.content instanceof FormControl) {
            this.content.setValue(editorcontent);
        } else {
            this.content[this.propertyName] = editorcontent;
        }
    }

    ngOnChanges(changes) {
        if (this.editor) {
            if (!this.content[this.propertyName])
                this.content[this.propertyName] = '';
            this.editor.setContent(this.content[this.propertyName]);
            this.editor.undoManager.clear();
        }
    };

    ngAfterViewInit() {
        this.setColorSet();
        setTimeout(() => {

            tinymce.init({
                selector: '#' + this.elementId + this.propertyName,
                base_url: "/tinymce",
                suffix: '.min',
                language: 'nl',
                menubar: 'insert edit format table',
                menu: {
                    insert: {title: 'Invoegen', items: 'charmap'},
                    edit: {title: 'Bewerken', items: 'undo redo | cut copy paste pastetext | selectall | code '},
                    format: {title: 'Stijl', items: ' bold italic underline | align | forecolor backcolor | removeformat '},
                    table: {title: 'Tabel', items: 'inserttable tableprops | cell row column'}
                },
                plugins: ['link', 'media', 'paste', 'table', 'lists', 'advlist', 'fullscreen', 'quickbars', 'charmap'],
                toolbar1: 'undo redo | bold italic | bullist numlist | link | indent outdent | fullscreen ',
                toolbar2: '',
                toolbar3: '',
                quickbars_selection_toolbar: 'bold italic underline quicklink h2 h3 charmap',
                skin: 'oxide',
                table_default_styles: {
                    width: '100%'
                },
                table_default_attributes: {
                    cellspacing: 0
                },
                style_formats: [
                    {title: 'Kop 1', block: 'h1'},
                    {title: 'Kop 2', block: 'h2'},
                    {title: 'Kop 3', block: 'h3'},
                    {title: 'Kleiner', inline: 'span', classes: 'textsmall'},
                    {title: 'Groter', inline: 'span', classes: 'textlarge'}
                ],
                min_height: 400,
                setup: editor => {
                    this.editor = editor;

                    editor.on('ExecCommand', () => {
                        editor.save();
                        this.updateview(editor);
                    });

                    editor.on('change', () => {
                        editor.save();
                        if (this.content instanceof FormControl) {
                            this.content.markAllAsTouched();
                        }
                        this.updateview(editor);
                    });

                    editor.on('ObjectResized', () => {
                        editor.save();
                        this.updateview(editor);
                    });

                }
            });


            if (!this.content[this.propertyName])
                this.content[this.propertyName] = '';

            if (this.editor) {
                setTimeout(() => {
                    this.editor.setContent(this.content[this.propertyName]);
                }, 300);
            }
        });
    }

    ngOnDestroy() {
        tinymce.remove(this.editor);
    }

    private setColorSet(): void {
        this.currentColorSet = this.colorSet[this.colorSetName];
    }

}
