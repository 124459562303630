<div class="container pt-3">
    <div class="row justify-content-center">
        <div class="col-md-4 col-lg-3">
            <ng-container *ngIf="user">
                <b>Foto: </b>
                <label class="m-0 p-0 pr-3">
                    <div [style.background-image]="user.extra?.photo | safeBgUrl" class="user-photo"></div>
                    <input (change)="uploadLogo($event)" hidden type="file">
                </label>
            </ng-container>
        </div>
        <div class="col-md-8 col-lg-7">
            <app-processbar *ngIf="!user"></app-processbar>
            <form #editForm="ngForm" (ngSubmit)="onSubmit()" *ngIf="user" role="form">
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field class="w-100">
                            <input [(ngModel)]="user.name" matInput name="name" placeholder="Naam"
                                   required type="text">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field class="w-100">
                            <input [(ngModel)]="user.email" matInput name="email" placeholder="Email"
                                   required type="email">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field class="w-100">
                            <input [(ngModel)]="user.phone" matInput name="phone" placeholder="Telefoon"
                                   type="text">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field class="w-100">
                            <mat-select [(ngModel)]="user.group" name="group"
                                        placeholder="Gebruikers rol" required>
                                <mat-option value="ADMIN">
                                    Admin
                                </mat-option>
                                <mat-option value="SUBADMIN">
                                    Gemeente Admin
                                </mat-option>
                                <mat-option value="GEMEENTE">
                                    Gemeente
                                </mat-option>
                                <mat-option value="CONTRACTOR">
                                    Aannemer
                                </mat-option>
                                <mat-option value="CONSUMER">
                                    Consument
                                </mat-option>
                                <mat-option value="READ">
                                    Alleen lezen
                                </mat-option>
                                <mat-option value="EMPLOYEE_READ">
                                    Alleen lezen medewerker
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <ul>
                            <li>
                                <b>Admin</b> heeft alle rechten binnen Dirk.
                            </li>
                            <li>
                                <b>Gemeente Admin</b> heeft alle rechten op gekoppelde klantgebieden, maar kan geen
                                gebruikers beheren.
                            </li>
                            <li>
                                <b>Gemeente</b> kan punten binnen zijn gemeente aanmaken en alle aangemaakte punten
                                zien.
                            </li>
                            <li>
                                <b>Aannemer</b> Op dit moment alleen voor straatwerk gemeente Weert. Waar Kessels
                                het straatwerk uitvoert. Kan alleen status veranderen, opmerking aanpassen en een
                                foto maken.
                            </li>
                            <li>
                                <b>Consument</b>
                                Kan zich inschrijven op alle projecten met toegang voor bewoners
                            </li>
                            <li>
                                <b>Alleen lezen</b> Kan punten bekijken in gekoppelde klantgebieden. Kan niets wijzigen.
                            </li>
                            <li>
                                <b>Alleen lezen medewerker</b> Kan punten bekijken. Kan niets wijzigen.
                            </li>
                        </ul>
                    </div>
                </div>
                <div *ngIf="user['townIds'] && user.group != 'ADMIN' && user.group != 'CONSUMER' && user.group != 'EMPLOYEE_READ'" class="row">
                    <div class="col-md-12">
                        <ng-select
                            [(ngModel)]="user['townIds']"
                            [closeOnSelect]="false"
                            [hideSelected]="true"
                            [items]="towns"
                            [multiple]="true"
                            bindLabel="code"
                            bindValue="id"
                            name="towns"
                            placeholder="Gemeenten"></ng-select>
                    </div>
                </div>
                <div class="row">
                    <div [ngClass]="{ 'error' : message.error }" class="col-md-12 col-lg-6 message">
                        {{message.message}}
                    </div>
                </div>
                <div class="row mb-4">

                    <div class="col-6">
                        <div *ngIf="user && !saveButtonDisabled && (user.id && userId)" class="text-left mrg-top-5">
                            <button (click)="deleteUser()" color="warn"
                                    mat-raised-button>Verwijderen
                            </button>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="text-right mrg-top-5">
                            <button [disabled]="saveButtonDisabled || !editForm.form.valid" color="primary" mat-raised-button
                                    type="submit">{{saveButtonText}}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
