import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {Workarea} from '../../workarea.class';
import {HttpClient} from '@angular/common/http';
import {Settings} from '../../settings.class';
import {Status} from '../../status.class';
import {Storage} from './../../storage.class';
import {WorkareaHistory} from '../../workarea-history';

@Injectable()
export class WorkareaService {

    private lastTownId = 0;
    private _workareas: Workarea[];
    private _statussus: object = {};

    constructor(private apiService: ApiService, private http: HttpClient) {
    }

    getHistory(workareaId) {
        return this.apiService.getCall$<WorkareaHistory[]>(`workareas/history/${workareaId}`);
    }

    public getAll(reload = false): Promise<Array<Workarea>> {
        return new Promise((resolve, reject) => {
            if (this.lastTownId != Storage.getTown()['id'] || reload) {
                return this.apiService.getCall('workareas', {mapSettings: JSON.stringify(Settings.mapSettings)}).then((workareas: Workarea[]) => {
                    this._workareas = workareas;
                    this.lastTownId = Storage.getTown()['id'];
                    resolve(workareas);
                }, err => {
                    reject(err);
                });
            } else {
                resolve(this._workareas);
            }
        });

    }

    public addWorkareaToCacheList(workarea) {
        if (typeof this._workareas !== 'undefined') {
            this._workareas.push(workarea);
        }
    }

    public resetLastTownId() {
        this.lastTownId = 0;
    }

    public getDetails(id: number): Promise<Workarea> {
        return this.apiService.getCall(`workareas/${id}`);
    }

    public saveWorkarea(workarea: Workarea): Promise<Object> {
        return this.apiService.postCall(`workareas`, {workarea: workarea});
    }

    public reserve(workarea: Workarea): Promise<Workarea> {
        return this.apiService.postCall(`workareas/reserve`, {workarea: workarea});
    }

    public removeReservation(workarea: Workarea): Promise<Workarea> {
        return this.apiService.postCall(`workareas/reserve/remove`, {workarea: workarea});
    }

    public getAvailableStatuses(towntype: string): Promise<Array<Status>> {
        return new Promise((resolve, reject) => {
            if (typeof this._statussus[towntype] === 'undefined') {
                return this.apiService.getCall(`workareas/available-statuses`, {towntype}).then((statuses: Status[]) => {
                    this._statussus[towntype] = statuses;
                    resolve(statuses);
                }, err => {
                    reject(err);
                });
            } else {
                resolve(this._statussus[towntype]);
            }
        });
    }

    public saveWorkareaPicture(base64): Promise<string> {
        return this.apiService.postCall(`workareas/savepicture`, {base64: base64});
    }

}
