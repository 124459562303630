<div *ngIf="town && project" class="container project">
    <div *ngIf="project.type !== 'drillings'" [class.hidden]="hideSidebar" class="sidebar">
        <div class="controls">
            <button (click)="sidebarToggle()" color="primary" mat-icon-button>
                <img src="../../../assets/images/icons/townprojects/hide.svg">
            </button>
            <div class="text" *ngIf="isMobile">
                Details en exporteren
            </div>
            <button mat-icon-button
                    *ngIf="isMobile"
                    color="primary"
                    routerLink="/towns/{{townId}}"
                    [queryParams]="{project:projectId}">
                <mat-icon class="fas fa-map"></mat-icon>
            </button>
        </div>
        <div class="content">
            <div class="manager">
                <div class="title">
                    Projectleider
                    <button *ngIf="Utils.userHasRights('ADMIN')" [routerLink]="['/users', project?.manager_user?.id, {backTo: '/towns/'+townId+'/projects/'+project.id}]"
                            color="primary"
                            mat-icon-button>
                        <mat-icon class="fa fa-edit"></mat-icon>
                    </button>
                </div>
                <div class="name">{{project?.manager_user?.name}}</div>
                <div [style.background-image]="project?.manager_user?.extra?.photo | safeBgUrl" class="photo"></div>
                <div class="phone">{{project.manager_user?.phone}}</div>
                <div class="email">{{project.manager_user?.email}}</div>
            </div>
            <div *ngIf="counts && townId" class="counts">
                <h1>Status Delen</h1>
                <div *ngFor="let count of counts" class="count">
                    <div [innerHTML]="count.count | marker : count.colorcode" class="marker"></div>
                    <div>{{count.name}}</div>
                </div>
            </div>
            <div *ngIf="townId && Utils.userHasRights('ADMIN')" class="actions">
                <button (click)="downloadReport()" color="primary" mat-flat-button [disabled]="generatingReport">
                    PDF
                    <i *ngIf="generatingReport" class="fas fa-circle-notch fa-spin"></i>
                </button>
                <button (click)="exportExcel()" color="primary" mat-flat-button [disabled]="generatingExcel">
                    Excel
                    <i *ngIf="generatingExcel" class="fas fa-circle-notch fa-spin"></i>
                </button>
                <button (click)="zipExport()" color="primary" mat-flat-button [disabled]="zipExporting">
                    ZIP
                    <i *ngIf="zipExporting" class="fas fa-circle-notch fa-spin"></i>
                </button>
            </div>
            <div class="d-flex justify-content-center">
                <button mat-button color="primary" (click)="projectDescription()" *ngIf="LocalStorage.getUser()?.group === 'ADMIN'">
                    Beschrijving aanpassen
                </button>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="header mt-2">
            <div class="row">
                <div class="col-auto pr-md-5">
                    <h2>
                        <span>{{town.name}}</span> {{project.name}}
                        <button *ngIf="Utils.userHasRights('ADMIN')" (click)="projectModal(project)" color="primary" mat-icon-button>
                            <mat-icon class="fa fa-edit"></mat-icon>
                        </button>
                    </h2>
                    <div class="d-flex align-items-baseline justify-content-between">
                        <h3 *ngIf="project.budget">Budget: &euro; {{project.budget | number:'0.0-2'}}</h3>
                        <small *ngIf="project?.town_manager_user?.name"
                               disabled="true"
                               [routerLink]="Utils.userHasRights('ADMIN') ? ['/users', project?.town_manager_user?.id, {backTo: '/towns/'+townId+'/projects/'+project.id}] : null"
                               [class.curpoint]="Utils.userHasRights('ADMIN')">
                            Projectleider gemeente: {{project.town_manager_user.name}}
                        </small>
                    </div>
                </div>
                <div class="col-auto justify-content-center align-items-center" *ngIf="!isMobile && project.type !== 'drillings'">
                    <a routerLink="/towns/{{townId}}" [queryParams]="{project:projectId}" class="button mx-0 ml-5">Delen op kaart bekijken</a>
                </div>
                <div class="col-lg col-12 justify-content-start align-items-start d-flex flex-wrap"
                     *ngIf="project.type === 'drillings' && townId">
                    <a (click)="downloadReport()" class="button mx-0 ml-2  mt-2">
                        {{generatingReport ? 'PDF wordt gemaakt...' : 'PDF'}}
                    </a>
                    <a (click)="exportExcel()" class="button mx-0 ml-2  mt-2 mt-2">
                        {{generatingExcel ? 'Excel wordt gemaakt...' : 'Excel'}}
                    </a>
                    <a routerLink="/towns/{{townId}}" [queryParams]="{project:projectId}" class="button mx-0 ml-2 mt-2">
                        Delen op kaart bekijken
                    </a>
                </div>
            </div>
            <app-town-logo *ngIf="!isMobile" [town]="town"></app-town-logo>
        </div>
        <div class="detail">
            <div *ngIf="project.type !== 'drillings'" class="row">
                <div class="col-md-6 col-lg-5 mb-3 mb-md-0">
                    <h1>Projectfasen</h1>
                    <app-town-project-phase-list
                        (phaseSelected)="selectedPhase = $event; selectedActivity = null;"
                        [currentProjectPhase]="project.town_project_phase"
                        [projectPhases]="project.town_project_phases"
                        [project]="project"
                        [selectedPhase]="selectedPhase">
                    </app-town-project-phase-list>
                </div>
                <div class="col-md-6 col-lg-7" cdkDropListGroup>
                    <h1>Activiteiten</h1>
                    <small *ngIf="!selectedPhase">
                        Selecteer een projectfase
                    </small>
                    <app-town-project-phase-activities *ngIf="selectedPhase"
                                                       (activitySelected)="selectedActivity = $event"
                                                       (replyDropped)="townProjectPhaseReplies.moveToActivity($event)"
                                                       [projectReplies]="selectedPhase.town_project_phase_replies"
                                                       [selectedActivity]="selectedActivity"
                                                       [activities]="selectedPhase.town_project_phase_activities"
                                                       [phase]="selectedPhase">
                    </app-town-project-phase-activities>
                    <h1 class="mt-3">Documenten {{selectedActivity ? 'van ' + selectedActivity.name : ''}}</h1>
                    <small *ngIf="!selectedPhase">
                        Selecteer een projectfase
                    </small>
                    <app-town-project-phase-replies #townProjectPhaseReplies *ngIf="selectedPhase"
                                                    [selectedActivity]="selectedActivity"
                                                    [phase]="selectedPhase"
                                                    [highlightId]="highlightActivityReplyId"
                                                    [replies]="selectedPhase.town_project_phase_replies">
                    </app-town-project-phase-replies>
                </div>
            </div>
            <div *ngIf="project.type === 'drillings' && form" class="row">
                <div class="col-md-8">
                    <h1>
                        Algemeen
                    </h1>
                    <div>
                        <form [formGroup]="form">
                            <mat-form-field class="w-100">
                                <mat-placeholder>Boormeester</mat-placeholder>
                                <input [formControl]="fc.driller" matInput type="text">
                                <mat-error [control]="fc.driller"></mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <mat-placeholder>Soort boring</mat-placeholder>
                                <mat-select [formControl]="fc.drillingType">
                                    <mat-option *ngFor="let option of dropdownOptions.drillingType" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-select>
                                <mat-error [control]="fc.drillingType"></mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <mat-placeholder>Soort fundering:</mat-placeholder>
                                <mat-select [formControl]="fc.foundationType">
                                    <mat-option *ngFor="let option of dropdownOptions.foundationType" [value]="option">
                                        {{option}}
                                    </mat-option>
                                </mat-select>
                                <mat-error [control]="fc.foundationType"></mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <mat-placeholder>Werknummer</mat-placeholder>
                                <input [formControl]="fc.worknumber" matInput type="text">
                                <mat-error [control]="fc.worknumber"></mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <mat-placeholder>Straatnaam</mat-placeholder>
                                <input [formControl]="fc.street" matInput type="text">
                                <mat-error [control]="fc.street"></mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100">
                                <mat-placeholder>Klantnaam</mat-placeholder>
                                <input [formControl]="fc.customer" matInput type="text">
                                <mat-error [control]="fc.customer"></mat-error>
                            </mat-form-field>
                            <mat-form-field (click)="picker.open()" class="w-100">
                                <mat-label>Datum uitvoering</mat-label>
                                <input [formControl]="fc.execution_date" [matDatepicker]="picker" matInput>
                                <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error [control]="fc.execution_date"></mat-error>
                            </mat-form-field>
                            <simple-tiny [content]="fc.description"
                                         elementId="description"
                                         ngDefaultControl
                                         propertyName="value"
                            ></simple-tiny>
                        </form>
                        <div class="mt-2 mb-2">
                            <button (click)="save()" class="button m-0">
                                Opslaan
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-5 mt-3 mt-md-0">
                    <h1>Documenten</h1>
                    <div class="document-list">
                        <div (cdkDropListDropped)="drop($event, project.extra.reports)" cdkDropList>
                            <div *ngFor="let file of project.extra.reports" cdkDrag class="document">
                                <div class="prefix">
                                    <div class="icon"></div>
                                    <button (click)="deleteReport(file)" color="warn" mat-icon-button>
                                        <mat-icon class="fas fa-trash"></mat-icon>
                                    </button>
                                </div>
                                <div>
                                    {{file}}
                                </div>
                            </div>
                        </div>
                        <app-document-upload (uploaded)="fileUploaded($event)"
                                             [buttonIcon]="true"
                                             [town]="town"
                                             currentPath="{{project.id}}"
                                             storage="drillings"></app-document-upload>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
