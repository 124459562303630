import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Datasource, IDatasource} from 'ngx-ui-scroll';
import {Platform} from '@angular/cdk/platform';
import {Clipboard} from '@angular/cdk/clipboard';
import {formatDate} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {BreakpointObserver} from '@angular/cdk/layout';
import {Keyboard} from '@ionic-native/keyboard/ngx';
import {Storage} from '../../storage.class';
import {ChatMessageInfoDialogComponent} from '../chat-message-info-dialog/chat-message-info-dialog.component';
import {ChatMessage} from '../chat-message';
import {Chat} from '../chat';
import {ChatMessageService} from '../chat-message.service';
import {ChatService} from '../chat.service';
import {IsBase64Pipe} from '../../pipes/is-base64.pipe';
import {Utils} from '../../utils.class';
import {ImageViewerDialogComponent} from '../../documents/image-viewer-dialog/image-viewer-dialog.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {GpsService} from '../../gps.service';
import {WorkareaService} from '../../services/workarea/workarea.service';
import {Status} from '../../status.class';
import {NewChatDialogComponent} from '../new-chat-dialog/new-chat-dialog.component';
import {Routenames} from '../../route-names.enum';
import {ChooseFileSourceComponent} from '../../file-manager/choose-file-source/choose-file-source.component';

declare var cordova;
declare var window;

@Component({
    selector: 'app-chat-messaging',
    templateUrl: './chat-messaging.component.html',
    styleUrls: ['./chat-messaging.component.scss']
})
export class ChatMessagingComponent implements OnInit, OnChanges, OnDestroy {

    form: FormGroup;
    messages: ChatMessage[] = [];
    @Output()
    openNextChat = new EventEmitter();
    @Input()
    chat: Chat;
    lastRead;
    enableDelete = false;
    enableCopy = false;
    enableInfo = false;
    sending = false;
    loaded = false;
    datasource: IDatasource<ChatMessage>;
    selectedItems = new Map<number, ChatMessage>();
    chatAdmin = false;
    isCordova = typeof cordova !== 'undefined';
    Routenames = Routenames;
    Utils = Utils;
    LocalStorage = Storage;
    statuses: Status[];
    private subscriptions = new Subscription();

    constructor(public chatMessageService: ChatMessageService,
                private clipboard: Clipboard,
                private chatService: ChatService,
                private platform: Platform,
                private matDialog: MatDialog,
                private confirmDialog: ConfirmDialogService,
                private router: Router,
                private breakpointObserver: BreakpointObserver,
                private dialog: MatDialog,
                private matBottomSheet: MatBottomSheet,
                private keyboard: Keyboard,
                private isBase64Pipe: IsBase64Pipe,
                private gpsService: GpsService,
                private workareaService: WorkareaService) {
    }

    ngOnInit(): void {
        this.workareaService.getAvailableStatuses('chat').then(statuses => {
            this.statuses = statuses;
        });
    }

    reopen() {
        this.chat.status_id = this.statuses.find(s => s.isdefault).id;
        this.chatService.saveChat(this.chat).subscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.datasource = null;
        this.form = new FormGroup({
            message: new FormControl(''),
            chat_id: new FormControl(this.chat.id)
        });
        if (this.chat) {
            this.lastRead = Math.min(...this.chat.chat_users.map(u => u.last_read));
            if (Storage.getUser().group === 'ADMIN') {
                this.chatAdmin = true;
            }
        }
        if (!this.datasource) {
            let loading = true;
            let first = true;
            this.subscriptions.add(this.chatMessageService.getList(this.chat.id).subscribe(messages => {
                if (!this.datasource) {
                    this.messages = [];
                    const totalCount = this.chatMessageService.totalCount;
                    for (let i = 1; i <= totalCount; i++) {
                        const aboveIndexToFill = (totalCount - messages.length);
                        if (i > aboveIndexToFill) {
                            this.messages.push(messages[messages.length - (totalCount - i) - 1]);
                        } else {
                            this.messages.push(new ChatMessage());
                        }
                    }
                    this.datasource = new Datasource({
                        get: (index, count, success) => {
                            if (!loading && this.messages.length > 0 && typeof this.messages[this.messages.length - 1].id !== 'undefined' && this.messages.filter(m => !!m.id).length !== totalCount) {
                                this.chatMessageService.requestMessages(this.chat.id, this.messages.find(m => !!m.id).id);
                                loading = true;
                            }
                            const min = 0;
                            const max = this.chatMessageService.totalCount;
                            const data = [];
                            const start = Math.max(min, index + 1);
                            const end = Math.min(index + count, max);
                            if (start <= end) {
                                for (let i = start; i <= end; i++) {
                                    if (typeof this.messages[i] !== 'undefined') {
                                        data.push(this.messages[i]);
                                    }
                                }
                            }
                            success(data);
                            if (first) {
                                first = false;
                            }
                        },
                        settings: {
                            startIndex: this.chat?.lastReadMessageIndex ? this.chat.lastReadMessageIndex - 2 : totalCount - 2,
                            bufferSize: 30
                        }
                    });
                    loading = false;
                    this.subscriptions.add(this.datasource.adapter.lastVisible$.subscribe(item => {
                        const chatUser = this.chat.chat_users.find(u => u.user_id === Storage.getUser().id);
                        if (chatUser && (!chatUser.last_read || item.data['id'] > chatUser.last_read)) {
                            chatUser.last_read = item.data['id'];
                            this.chatService.lastRead(this.datasource.adapter.lastVisible.data.id, this.chat.id).subscribe();
                        }
                    }));
                } else {
                    let scrolledToEnd = this.datasource.adapter.lastVisible.$index >= (this.chatMessageService.totalCount - 3);
                    let newData = false;
                    if (this.messages.length < this.chatMessageService.totalCount) {
                        newData = true;
                        for (let i = (this.messages.length); i < this.chatMessageService.totalCount; i++) {
                            const m = new ChatMessage();
                            this.messages.push(m);
                            this.datasource.adapter.append(m, true);
                        }
                    }
                    const newLenght = messages.length;
                    const fromIndex = this.messages.length - newLenght;
                    messages.forEach((m, i) => {
                        Object.assign(this.messages[fromIndex + i], m);
                    });
                    loading = false;
                    if (newData && scrolledToEnd) {
                        setTimeout(() => {
                            document.querySelector('.messages').scrollBy(0, 99999);
                        });
                        setTimeout(() => {
                            document.querySelector('.messages').scrollBy(0, 99999);
                        }, 500);
                    }
                }
            }, error => {
                this.confirmDialog.confirm(
                    'Probleem met laden van berichten',
                    'Het is niet gelukt om deze chat te laden',
                    'Opnieuw proberen',
                    'Terug'
                ).then(() => {
                    this.subscriptions.unsubscribe();
                    this.ngOnInit();
                }, () => {
                    this.router.navigateByUrl(Routenames.chat);
                });
            }));
            this.keyboard.onKeyboardDidShow().subscribe(event => {
                if (this.datasource.adapter.lastVisible.$index >= (this.chatMessageService.totalCount - 3)) {
                    setTimeout(() => {
                        document.querySelector('.messages').scrollBy(0, 99999);
                    });
                }
            });
        }


    }

    sendMessage(event?: KeyboardEvent) {
        if (!event || (event.key === "Enter" && !event.shiftKey)) {
            this.form.value['message'] = this.form.value['message'].trim();
            if (!this.sending && this.form.value['message']) {
                this.sending = true;
                this.chatMessageService.newMessage(this.form.value);
                this.form.get('message').reset();
                this.sending = false;
            }
        }
    }

    readInfo(event) {
        event.stopPropagation();
        const isMobile = this.breakpointObserver.isMatched('(max-width: 450px)');
        let dialogRef = this.dialog.open(ChatMessageInfoDialogComponent, {
            maxHeight: '98vh',
            minHeight: isMobile ? '100%' : '500px',
            height: isMobile ? '100%' : '700px',
            minWidth: isMobile ? '100%' : '400px',
            width: isMobile ? '100%' : '400px',
            panelClass: 'chat-dialog',
            data: {
                chat: this.chat,
                message: Array.from(this.selectedItems.values())[0]
            }
        });
    }

    deleteMessages(event) {
        event.stopPropagation();
        this.chatMessageService.deleteMessage(Array.from(this.selectedItems.keys())).subscribe(() => {
            this.selectedItems.clear();
        });
    }

    copyMessage(event) {
        event.stopPropagation();
        let toCopy = '';
        Array.from(this.selectedItems.values()).forEach(message => {
            toCopy += `📋${this.chat.chat_users.find(c => c.user_id === message.user_id)?.user?.name || 'D. van der Steen'} schreef op ${formatDate(message.updated_at, 'd MMM yyyy HH:mm', 'nl')}
    ${message.message}
`;
        });
        this.clipboard.copy(toCopy.replace(/<br\s*[\/]?>/gi, ''));
        this.selectedItems.clear();
    }

    selectStart(message: ChatMessage) {
        if (this.selectedItems.size < 1 && !message.deleted_at) {
            this.selectedItems.set(message.id, message);
        }
        this.checkActions();
    }

    select($event, message: ChatMessage) {
        if (this.selectedItems.size > 0
            && !message.deleted_at
            && (
                ($event instanceof TouchEvent && this.platform.IOS)
                || ($event.sourceCapabilities && $event.sourceCapabilities.firesTouchEvents && $event instanceof TouchEvent)
                || ((!$event.sourceCapabilities || !$event.sourceCapabilities.firesTouchEvents) && $event instanceof MouseEvent)
            )) {
            if (this.selectedItems.has(message.id)) {
                this.selectedItems.delete(message.id);
            } else {
                this.selectedItems.set(message.id, message);
            }
        }
        this.checkActions();
    }

    checkActions() {
        const treshold = new Date();
        treshold.setHours(treshold.getHours() - 1);
        this.enableDelete = !Array.from(this.selectedItems.values())
            .find(m => new Date(m.updated_at).getTime() < treshold.getTime() || m.user_id !== Storage.getUser().id);
        this.enableCopy = !Array.from(this.selectedItems.values())
            .find(m => this.isBase64Pipe.transform(m.message));
        this.enableInfo = this.selectedItems.size === 1 && Array.from(this.selectedItems.values())
            .filter(m => m.user_id === Storage.getUser().id).length === 1;
    }

    camera(event) {
        event.stopPropagation();
        event.preventDefault();
        const ref = this.matBottomSheet.open(ChooseFileSourceComponent, {
            data: {
                rotate: false,
                remove: false
            },
            panelClass: 'choose-file-dropupmenu'
        });
        const sheetDismissSubs = ref.afterDismissed().subscribe(type => {
            if (typeof cordova !== 'undefined' && type) {
                navigator['camera'].getPicture((data) => {
                    const message = new ChatMessage();
                    message.message = "data:image/jpeg;base64," + data;
                    message.chat_id = this.chat.id;
                    if (type === 'camera') {
                        this.gpsService.getCurrentPosition().then((geo) => {
                            message.lat = geo['latitude'];
                            message.lng = geo['longitude'];
                            this.chatMessageService.newMessage(message);
                        }, () => {
                            this.chatMessageService.newMessage(message);
                        });
                    } else {
                        this.chatMessageService.newMessage(message);
                    }
                }, (data) => {

                }, {
                    quality: 50,
                    targetWidth: 1600,
                    targetHeight: 1600,
                    destinationType: 0,
                    sourceType: type === 'camera' ? 1 : 0,
                    correctOrientation: true
                });
            }
            sheetDismissSubs.unsubscribe();
        });
    }

    uploadFile(event) {
        event.stopPropagation();
        event.preventDefault();
        const files = event.srcElement.files;
        for (let i = 0; i < files.length; i++) {
            const img = new Image();
            img.src = window.URL.createObjectURL(files[i]);
            img.onload = () => {
                const factor = 1600 / Math.max(img.naturalWidth, img.naturalHeight);
                const width = factor < 1 ? img.naturalWidth * factor : img.naturalWidth;
                const height = factor < 1 ? img.naturalHeight * factor : img.naturalHeight;
                const elem = document.createElement('canvas');
                elem.width = width;
                elem.height = height;
                const ctx = elem.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);
                const message = new ChatMessage();
                message.message = ctx.canvas.toDataURL('image/jpeg', 0.7);
                message.chat_id = this.chat.id;
                this.chatMessageService.newMessage(message);
            };
        }
    }

    openImage(image: string) {
        if (this.selectedItems.size < 1) {
            const images = this.messages.filter(m => m.message && m.message.substr(0, 11) === 'data:image/').map(m => m.message);
            const dialogRef = this.matDialog.open(ImageViewerDialogComponent, {
                panelClass: 'image-viewer-dialog',
                data: {
                    documentImages: images,
                    viewIndex: images.indexOf(image)
                }
            });
        }
    }

    editChat() {
        const isMobile = this.breakpointObserver.isMatched('(max-width: 450px)');
        let dialogRef = this.dialog.open(NewChatDialogComponent, {
            maxHeight: '98vh',
            minHeight: isMobile ? '100%' : '500px',
            height: isMobile ? '100%' : '700px',
            minWidth: isMobile ? '100%' : '400px',
            width: isMobile ? '100%' : '400px',
            panelClass: 'confirm-dialog',
            data: this.chat
        });
    }

    statusChange(status: Status) {
        this.chat.status_id = status.id;
        this.chatService.saveChat(this.chat).subscribe(() => {
            this.openNextChat.emit();
        });
    }

    openNavi() {
        const geocoords = this.chat.lat + ',' + this.chat.lng;
        if (Utils.isIOS()) {
            window.location = 'maps://?q=' + geocoords;
        } else if (this.platform.ANDROID) {
            const label = encodeURI('Punaise: ' + this.chat.id);
            window.location = 'geo:0,0?q=' + geocoords + '(' + label + ')';
        } else {
            window.open('https://google.com/maps?q=' + geocoords, '_blank');
        }
    }


    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
