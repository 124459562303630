<div class="container  h-100">
    <ng-container *ngIf="isConsumer && projects && projects.length === 0">
        <h4 class="mt-5">Volg een van onze projecten</h4>
        <p>
            Je hebt je nog niet ingeschreven voor een van onze projecten of je project is afgerond..
            Open je profiel rechtsonderin om een project te volgen.
        </p>
    </ng-container>
    <div [class.d-md-none]="!isConsumer" class="projects">
        <p *ngIf="!isConsumer && projects && projects.length === 0" class="text-center w-100">
            Er zijn nog geen (deel)opdrachten.
        </p>
        <cdk-virtual-scroll-viewport itemSize="327" class="w-100 h-100">
            <ng-container *cdkVirtualFor="let project of projects">
                <div class="project">
                    <div class="name"><span>{{project.name}}</span></div>
                    <div class="divider"></div>
                    <ng-container *ngIf="!isConsumer">
                        <div class="manager">
                            {{project.manager_user?.name}}
                        </div>
                        <div *ngIf="project?.town_project_phase?.name" class="current-phase">
                            Huidige fase: {{project?.town_project_phase?.name}}
                        </div>
                        <div class="divider"></div>
                    </ng-container>

                    <div class="buttons">
                        <a *ngIf="!isConsumer && project.town_id !== 0" [queryParams]="{project:project.id}" class="button" routerLink="/towns/{{project.town_id}}">
                            <svg-icon src="/assets/images/icons/map.svg"></svg-icon>
                            <span>Kaart ({{project.workarea_count}})</span>
                        </a>
                        <a *ngIf="!isConsumer" class="button" routerLink="/towns/{{project.town_id || 'template'}}/projects/{{project.id}}">
                            <svg-icon src="/assets/images/icons/townprojects/document_white.svg"></svg-icon>
                            <span>Documentatie</span>
                        </a>
                        <a *ngIf="project.town_id !== 0" class="button"
                           routerLink="/{{Routenames.towns}}/{{project.town_id || 'template'}}/{{Routenames.projects}}/{{project.id}}/{{Routenames.timeline}}">
                            <svg-icon src="/assets/svg/work.svg"></svg-icon>
                            <span>Tijdlijn</span>
                        </a>
                        <a *ngIf="project.town_id !== 0" class="button"
                           routerLink="/{{Routenames.towns}}/{{project.town_id || 'template'}}/{{Routenames.projects}}/{{project.id}}/{{Routenames.contacts}}">
                            <svg-icon src="/assets/images/icons/contacten_white.svg"></svg-icon>
                            <span>Contactpersonen ({{project.contact_count}})</span>
                        </a>
                    </div>
                </div>
            </ng-container>
        </cdk-virtual-scroll-viewport>
    </div>

    <table (matSortChange)="sortProjects($event)"
           *ngIf="projects?.length > 0 && !isConsumer"
           [dataSource]="projects"
           [matSortActive]="currentSort.active"
           [matSortDirection]="currentSort.direction"
           class="mat-elevation-z8 w-100 mb-4 d-none d-md-block"
           mat-table
           matSort>
        <ng-container matColumnDef="town">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Klantgebied</th>
            <td *matCellDef="let project" mat-cell>
                <a routerLink="/{{Routenames.towns}}/{{project.town_id}}/{{Routenames.projects}}">
                    {{(towns | byId:project.town_id)?.name}}
                </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Projectnaam</th>
            <td *matCellDef="let project" mat-cell>
                <a routerLink="/{{Routenames.towns}}/{{project.town_id}}/{{Routenames.projects}}/{{project.id}}">
                    {{project.name}}
                </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="type">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Type</th>
            <td *matCellDef="let project" mat-cell>
                {{project.type | projectType}}
            </td>
        </ng-container>
        <ng-container matColumnDef="consumers">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Consumenten</th>
            <td *matCellDef="let project" mat-cell> {{project.consumers ? 'Ja' : ''}} </td>
        </ng-container>
        <ng-container matColumnDef="manager">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Projectleider</th>
            <td *matCellDef="let project" mat-cell> {{project.manager_user?.name || '-'}} </td>
        </ng-container>
        <ng-container matColumnDef="phase">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>Actieve fase</th>
            <td *matCellDef="let project" mat-cell> {{project.town_project_phase?.name || '-'}} </td>
        </ng-container>
        <ng-container matColumnDef="workarea_count">
            <th *matHeaderCellDef mat-header-cell mat-sort-header width="50">Aantal delen</th>
            <td *matCellDef="let project" mat-cell> {{project.workarea_count | number:'0.0-0'}} </td>
        </ng-container>
        <ng-container matColumnDef="latest_activity">
            <th *matHeaderCellDef mat-header-cell mat-sort-header width="200">Laatste wijziging</th>
            <td *matCellDef="let project" mat-cell> {{project.latest_activity | date: 'EEE d MMM yyyy HH:mm'}} </td>
        </ng-container>
        <ng-container matColumnDef="created_at">
            <th *matHeaderCellDef mat-header-cell mat-sort-header width="200">Datum aangemaakt</th>
            <td *matCellDef="let project" mat-cell> {{project.created_at | date: 'EEE d MMM yyyy HH:mm'}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th *matHeaderCellDef mat-header-cell>Bewonersinformatie</th>
            <td *matCellDef="let project" mat-cell>
                <a *ngIf="project.consumers"
                   matTooltip="Contactpersonen"
                   routerLink="/{{Routenames.towns}}/{{project.town_id}}/{{Routenames.projects}}/{{project.id}}//{{Routenames.contacts}}">
                    <i class="ml-3 fa fa-group"></i>
                </a>
                <a *ngIf="project.consumers"
                   matTooltip="Tijdlijn"
                   routerLink="/{{Routenames.towns}}/{{project.town_id}}/{{Routenames.projects}}/{{project.id}}//{{Routenames.timeline}}">
                    <i class="ml-3 fa fa-list-ol"></i>
                </a>
                <a *ngIf="project.consumers"
                   matTooltip="Updates"
                   routerLink="/{{Routenames.articles}}">
                    <i class="ml-3 fa fa-wifi"></i>
                </a>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
</div>
<ng-template #projectManagerFilterTemplate>
    <mat-form-field class="mr-1">
        <mat-label>Filter</mat-label>
        <mat-select multiple [(ngModel)]="projectFilter" (selectionChange)="getProjects()">
            <mat-option *ngFor="let type of Settings.projectTypes" [value]="type.key">
                {{type.name}}
            </mat-option>
            <mat-option value="archive">
                Gearchiveerd
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Filter projectleider</mat-label>
        <mat-select multiple [(ngModel)]="projectManagerFilter" (selectionChange)="changeProjectMangerFilter()">
            <mat-option *ngFor="let projectManager of projectManagers" [value]="projectManager.id">
                {{projectManager.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-template>
