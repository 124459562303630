<div class="d-flex flex-row flex-wrap"
     dndDropzone
     dndHorizontal="true"
     dndEffectAllowed="copyMove"
     (dndDrop)="onDrop($event, activities)"
>
    <div class="activity placeholder" dndPlaceholderRef>

    </div>
    <div *ngFor="let activity of activities"
         cdkDropList
         matRipple
         dndEffectAllowed="copyMove"
         (dndCopied)="onDragged(activity, activities, 'copy')"
         (dndLinked)="onDragged(activity, activities, 'link')"
         (dndMoved)="onDragged(activity, activities, 'move')"
         (dndCanceled)="onDragged(activity, activities, 'none')"
         [dndDisableIf]="disableDragDrop"
         [dndDraggable]="activity"
         (cdkDropListDropped)="drop($event, activity)"
         (click)="selectActivity(activity)"
         class="activity curpoint"
         [class.selected]="selectedActivity?.id === activity.id">
        <div class="executed">
            <mat-checkbox [disabled]="!Utils.userHasRights('ADMIN')" (change)="execute($event, activity)" [checked]="!!activity.executed" color="primary"></mat-checkbox>
        </div>
        <div class="data">
            <div class="title">
                {{activity.order}}. {{activity.name}}
            </div>
            <div>
                <b>Verantwoordelijke: </b> {{activity.assignee}}
            </div>
            <div>
                <b>Deadline: </b> {{activity.deadline | date : 'd MMMM yyyy'}}
            </div>
        </div>
        <div class="edit">
            <button (click)="edit(activity)" color="primary" mat-icon-button *ngIf="Utils.userHasRights('ADMIN')">
                <mat-icon class="fa fa-edit"></mat-icon>
            </button>
            <div>
                <ng-container *ngIf="(projectReplies | filter : activity?.id : 'townprojectphase_activity_id')?.length">
                    {{(projectReplies | filter : activity?.id : 'townprojectphase_activity_id')?.length}}
                    <i class="fas fa-file"></i>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div class="d-flex flex-row flex-wrap">
    <div class="activity">
        <div class="data">
            <button *ngIf="selectedActivity" mat-button color="primary" (click)="selectActivity(null)">
                Toon overige documenten
            </button>
        </div>
    </div>
    <div [class.empty]="activities?.length < 1" class="activity add" *ngIf="Utils.userHasRights('ADMIN')">
        <div class="data">
            <button (click)="edit()" color="primary" mat-button>
                Activiteit toevoegen <img src="../../../assets/images/icons/townprojects/add.svg">
            </button>
        </div>
    </div>

</div>
