import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';

@Injectable()
export class ExportService {

    constructor(private apiService: ApiService) {
    }

    public exportPdfWorkareas(townid: number): Promise<Object> {
        return this.apiService.getCall(`export/pdf/workareas`, {townid});
    }

    public exportExcelWorkareas(townid: number): Promise<Object> {
        return this.apiService.getCall(`export/excel/workareas`, {townid});
    }

    public downloadExcelWorkareas(townproject_id?: number) {
        let params = {};
        if (townproject_id) {
            params = {download: true, townproject_id: townproject_id};
        } else {
            params = {download: true};
        }
        return this.apiService.getBlobCall(`export/excel/workareas`, params);
    }


    public exportExcelConsumers(townid: number): Promise<Object> {
        return this.apiService.getCall(`export/excel/consumers`, {townid});
    }

}
