import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {WorkareaService} from '../services/workarea/workarea.service';
import {Workarea} from '../workarea.class';
import {Storage} from '../storage.class';
import {CameraService} from '../camera.service';
import {WorkareaImage} from '../workarea-image';
import {Settings} from '../settings.class';
import {LocationService} from './shared/location.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Utils} from '../utils.class';
import {Status} from '../status.class';
import {ConfirmModalService} from '../shared/confirm-modal.service';
import {CordovaService} from '../cordova.service';
import {FieldOptionsServiceService} from './shared/field-options-service.service';
import {Subscription} from 'rxjs';
import {TownProjectService} from '../services/townproject/town-project.service';
import {map} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {WorkareaHistoryDialogComponent} from './workarea-history-dialog/workarea-history-dialog.component';
import {TownProject} from '../town-project';
import {PdfreportService} from '../pdfreport.service';
import {saveAs} from 'file-saver';
import {Platform} from '@angular/cdk/platform';

@Component({
    selector: 'app-workarea',
    templateUrl: './workarea.component.html',
    styleUrls: ['./workarea.component.scss']
})
export class WorkareaComponent implements OnInit, OnDestroy {

    @Input()
    public currentCoords;

    Storage = Storage;
    Utils = Utils;
    modalReflocationsModal: BsModalRef;
    workarea: Workarea;
    statuses: Array<Status> = [];
    loaded = false;
    newWorkarea = false;
    isWorkareaComponent = true;
    addresses: Array<string>;
    public activeImage: number;
    public somenumber = 1;
    public townType = '';
    public fieldOptions = {};
    fieldTranslations = {
        'street': 'Straat',
        'housenumber': 'Huisnummer',
        'zipcode': 'Postcode',
        'place': 'Plaats',
        'type': 'Type',
        'service': 'Service',
        'servicedate': 'Datum',
        'location_damage': 'Schade Locatie',
        'status': 'Status',
        'toplayer': 'Toplaag',
        'wearlayer': 'Slijtlaag',
        'execution': 'Uitvoering',
        'roadblock': 'Afzetting',
        'drillLocation': 'Boorlocatie',
        'lane': 'Rijstrook',
        'damage': 'Type',
        'genpriority': 'Prioriteit',
        'waymark': 'Wegmarkering',
        'bottomlayer': 'Onderlaag',
        'interlayer': 'Tussenlaag',
        'status_id': 'Status',
        'townproject_id': 'Project',
        'comments': 'Opmerkingen',
        'road_number': 'Wegnummer',
        'klinkerfloor': 'Betreft klinkervak',
        'reinforcementgrid': 'Bewapeningsnet gebruiken'
    };

    typeTownTypeMap = new Map<string, string>(
        [
            ['regular', 'general'],
            ['maintenance', 'general'],
            ['drillings', 'drillings']
        ]
    );

    bsConfig = {
        showWeekNumbers: false,
        minDate: new Date(new Date().setDate(new Date().getDate() + 1)),
        dateInputFormat: 'DD-MM-YYYY'
    };
    public userIsContractor = false;
    projects: TownProject[];

    @ViewChild('locationsModal', {static: true}) private templateRef: TemplateRef<any>;
    private isReserved = false;
    private forms = new Map<string, FormGroup>([]);
    private subscriptions = new Subscription();

    generatingReport = false;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private dialog: MatDialog,
                private pdfreportService: PdfreportService,
                private workareaService: WorkareaService,
                public platform: Platform,
                private projectService: TownProjectService,
                private cameraService: CameraService,
                private locationService: LocationService,
                private modalService: BsModalService,
                private fieldOptionService: FieldOptionsServiceService,
                private confirmModalService: ConfirmModalService) {
    }

    ngOnInit() {
        if (Storage.getUser().group === 'CONTRACTOR') {
            this.userIsContractor = true;
        }
        CordovaService.setBackbuttonActionUrl('/workareas');
        this.subscriptions.add(this.route.params.subscribe(params => {
            this.forms = new Map<string, FormGroup>([]);
            this.loaded = false;
            const id = +params['workareaId'];
            const queryParams = this.route.snapshot.queryParams;

            if (isFinite(id) && id > 0) {
                this.getWorkarea(id);
            } else {
                this.workarea = new Workarea();
                this.workarea.lat = Settings.mapCenter.latitude;
                this.workarea.lng = Settings.mapCenter.longitude;
                this.workarea.extra.servicedate = new Date(new Date().setDate(new Date().getDate() + 1));
                this.workarea.extra.servicedate.setHours(8);
                this.workarea.extra.servicedate.setMinutes(0);
                this.workarea.extra.priority = 'Hoog - opgelost binnen een dag';
                const projectId = params['project_id'] || queryParams['project'];
                if (projectId) {
                    this.projectService.getProject(projectId).pipe(map(p => p.data)).subscribe(project => {
                        this.townType = this.typeTownTypeMap.get(project.type);

                        this.workarea.type = project.type;
                        this.workarea.townproject_id = project.id;
                        this.newWorkarea = true;
                        this.loaded = true;
                        this.reserveWorkarea();
                    });
                } else {
                    this.townType = Storage.getTown()['towntype'];
                    this.newWorkarea = true;
                    this.loaded = true;
                    this.reserveWorkarea();
                }
            }


        }));
    }

    history() {
        this.dialog.open(WorkareaHistoryDialogComponent, {
            panelClass: 'confirm-dialog',
            disableClose: false,
            data: {
                workarea: this.workarea,
                fieldTranslations: this.fieldTranslations,
                statuses: this.statuses,
                projects: this.projects
            }
        });
    }

    pdf() {
        this.generatingReport = true;
        this.pdfreportService.getWorkareaPdf(this.workarea.id).subscribe(document => {
            this.generatingReport = false;
            saveAs(document, `werkvak_${this.workarea.number}.pdf`);
        }, () => {
            this.generatingReport = false;
        });
    }

    getWorkarea(id: number) {

        this.workareaService.getDetails(id).then(workarea => {
            this.workarea = workarea;
            this.townType = this.workarea.type ? this.typeTownTypeMap.get(this.workarea.type) : Storage.getTown()['towntype'];
            this.getOptionsAndStatusses();
            this.workarea.extra = this.workarea.extra ? this.workarea.extra : {};
            this.workarea.extra.servicedate = new Date(this.workarea.extra.servicedate || null);
            if (workarea.townproject_id) {
                this.router.navigate([], {
                    queryParams: {project: workarea.townproject_id},
                    queryParamsHandling: "merge"
                });
            }

            if (this.workarea.extra.length === 0) {
                this.workarea.extra = {};
            }
            if (typeof this.workarea.areas === 'string') {
                this.workarea.areas = JSON.parse(this.workarea.areas);
                if (typeof this.workarea.areas === 'string') {
                    this.workarea.areas = [];
                }
            }
            if (!this.workarea.extra.wearlayer) {
                this.workarea.extra.wearlayer = 'Onbekend';
            }
            if (!this.workarea.extra.execution) {
                this.workarea.extra.execution = 'Onbekend';
            }
            setTimeout(() => {
                this.loaded = true;
            });
            this.projectService.getProjects(false, this.workarea.town_id).subscribe(data => {
                this.projects = data.data.filter(p => p.type === this.workarea.type);
            });
        }, error => {
            this.confirmModalService.showModal(error.message.error.error, error.message.error.errormessage, 'Terug', null).then(() => {
                this.router.navigate(['towns', Storage.getTown()['id']], {queryParamsHandling: 'merge'});
            }, () => {

            });
        });
    }

    openNavi() {
        const geocoords = this.workarea.lat + ',' + this.workarea.lng;
        if (Utils.isIOS()) {
            window.location.href = 'maps://?q=' + geocoords;
        } else if (this.platform.ANDROID) {
            const label = encodeURI('DvdS nummer: ' + this.workarea.number);
            window.location.href = 'geo:0,0?q=' + geocoords + '(' + label + ')';
        } else {
            window.open('https://google.com/maps?q=' + geocoords, '_blank');
        }
    }

    save(forceWithoutImage = false) {
        let allValid = true;
        let errorMessage = '<b>Verbeter de volgende velden:</b><br>';
        this.forms.forEach((formGroup: FormGroup, form: string) => {
            if (allValid && formGroup.status !== 'DISABLED') {
                allValid = formGroup.valid;
            }
            for (const name in formGroup.controls) {
                if (formGroup.controls[name].invalid) {
                    errorMessage += `${this.fieldTranslations[name] || name} is verplicht<br>`;
                }
                formGroup.controls[name].markAsTouched();
            }
        });

        if (!allValid) {

            this.confirmModalService.showModal('Controleer je invoer', errorMessage, 'Oke', null)
                .then(() => {
                });

            return;
        }

        let stateHasImage = false;
        this.workarea.images.forEach((image) => {
            if (!image.id) {
                image.status_id = this.workarea.status_id;
                image.user_id = Storage.getUser().id;
                image.workarea_id = this.workarea.id;
            }
            if (image.status_id == this.workarea.status_id) {
                stateHasImage = true;
            }
        });

        const noImageRequired = (workarea: Workarea) => {
            if (this.townType === 'drillings' ||
                (workarea.extra && workarea.extra['vogzang_type'] && workarea.extra['vogzang_type'] === 'Service')) {
                return true;
            }
            return false;
        };

        this.statuses.forEach(status => {
            if (status.id == this.workarea.status_id) {
                this.workarea.status = status;
            }
        });

        if (!forceWithoutImage && !noImageRequired(this.workarea) && this.workarea.status.imagerequired && !stateHasImage) {
            if (Settings.isApp()) {
                this.confirmModalService.showModal(
                    'Een foto is verplicht',
                    'Wil je een foto maken?',
                    'Oké',
                    'Annuleren'
                ).then(() => {
                    this.takePhoto();
                }, () => {
                });
            } else {
                this.confirmModalService.showModal(
                    'Een foto is verplicht voor deze status',
                    'Toch opslaan zonder foto?',
                    'Opslaan zonder foto',
                    'Status aanpassen'
                ).then(() => {
                    this.save(true);
                }, () => {
                });
            }
        } else {
            this.workareaService.saveWorkarea(this.workarea).then((data) => {
                this.isReserved = false;
                if (this.workarea.id) {
                    this.workareaService.getAll(true).then(() => {
                        this.router.navigate(['towns', this.workarea.town_id]);
                    });
                } else {
                    this.workarea = data['workarea'];
                    if (this.workarea.has_documents) {
                        this.router.navigate(['towns', this.workarea.town_id, 'documents', this.workarea.id]);
                    }
                    this.workareaService.addWorkareaToCacheList(this.workarea);
                    setTimeout(() => {
                        this.router.navigate(['towns', this.workarea.town_id]);
                    }, 250);
                }

            }, (error) => {
                if (error.error.error.error == 'WORKAREA_UPDATED_AT_OLDER_THEN_DBVERSION') {
                    let messageText = `${error.error.error.errormessage}\nU kunt een kopie opslaan met een nieuw nummer.`;
                    this.confirmModalService.showModal(
                        'Opslaan is mislukt',
                        messageText, 'Kopie Opslaan', 'Annuleren'
                    ).then(() => {
                        this.workarea.comments = `[Conflict kopie van ${this.workarea.number}]\n${this.workarea.comments}`;
                        this.workarea.id = null;
                        this.workarea.images.forEach(img => {
                            img.id = null;
                        });
                        this.save();
                    });
                } else {
                    this.confirmModalService.showModal(
                        'Opslaan is mislukt',
                        error.error.error.errormessage, 'Oké', null
                    );
                }
            });
        }
    }

    takePhoto() {
        this.cameraService.getPicture().then((data: string) => {
            const workareaImage = new WorkareaImage();
            this.workareaService.saveWorkareaPicture(data).then((imageUrl) => {
                workareaImage.status = {};
                workareaImage.status['name'] = this.workarea.status_name;
                workareaImage.updated_at = new Date();
                workareaImage.user = Storage.getUser();
                workareaImage.image_url = Settings.API_ENDPOINT + imageUrl['imageUrl'];
                this.workarea.images.push(workareaImage);
            }, () => {

                this.confirmModalService.showModal(
                    'Foto maken mislukt', 'Er is iets misgegaan met het nemen van een foto.'
                );

            });
        }, (data) => {

        });
    }

    setLocation(address: any) {

        let number: any = '0';
        let street: any = '-';


        if (typeof address.addressLine !== 'undefined') {
            number = address.addressLine.split(' ').reverse()[0];

            if (isNaN(number[0])) {
                street = address.addressLine;
                number = '-';
            } else {
                street = address.addressLine.split(' ');

                delete street[street.indexOf(number)];
                street = street.join(' ');
            }
        } else {
            street = address.formattedAddress.split(', ')[0];
        }

        this.workarea.zipcode = address.postalCode;
        this.workarea.street = street;
        this.workarea.housenumber = number;
        this.workarea.place = address.locality;
        if (typeof this.modalReflocationsModal !== 'undefined') {
            this.modalReflocationsModal.hide();
        }


    }

    getLocations(lat: string, long: string) {
        this.subscriptions.add(this.locationService.getLocations(lat, long)
            .subscribe(response => {
                this.addresses = response['resourceSets'][0]['resources'];
                if (this.addresses.length > 1) {
                    this.modalReflocationsModal = this.modalService.show(this.templateRef, {class: 'confirmPopup'});
                } else if (this.addresses.length == 1) {
                    this.setLocation(this.addresses[0]['address']);
                }
            }));
    }

    public isFromValid(valid: FormGroup, form) {
        this.forms.set(form, valid);
    }

    delete() {
        if (this.isReserved) {
            this.workareaService.removeReservation(this.workarea).then(() => {
                this.router.navigate(['towns', this.workarea.town_id], {queryParamsHandling: 'merge'});
            });
        } else {
            this.confirmModalService.showModal(
                'Verwijderen',
                'Weet je zeker dat je deze boorlocatie wilt verwijderen?',
                'Verwijderen',
                'Behouden').then(() => {
                this.workareaService.removeReservation(this.workarea).then(() => {
                    this.router.navigate(['towns', this.workarea.town_id], {queryParamsHandling: 'merge'});
                });
            }, () => {

            });
        }

    }

    ngOnDestroy(): void {
        if (this.isReserved) {
            this.workareaService.removeReservation(this.workarea);
        }
        this.subscriptions.unsubscribe();
    }

    private reserveWorkarea() {
        this.getLocations(Settings.mapCenter.latitude, Settings.mapCenter.longitude);
        this.workareaService.reserve(this.workarea).then(workarea => {
            Object.assign(this.workarea, workarea);
            this.isReserved = true;
            if (this.townType === 'general') {
                this.workarea.extra.execution = 'Dag';
                this.workarea.extra.wearlayer = 'Nee';
                this.workarea.extra.genpriority = 'Onbekend';
                this.workarea.extra.damage = 'Algemene schade';
            }
        });
        this.getOptionsAndStatusses();
    }

    private getOptionsAndStatusses() {
        this.workareaService.getAvailableStatuses(this.townType).then(statuses => {
            this.statuses = statuses;
            if (this.newWorkarea) {
                this.statuses.forEach(status => {
                    if (status.isdefault) {
                        this.workarea.status = status;
                        this.workarea.status_id = status.id;
                    }
                });
            }

        });
        this.fieldOptionService.getOptions(this.townType).then(options => {
            this.fieldOptions = options;
        });
    }

}
