import {EventEmitter, Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {User} from '../../user.class';
import {Storage} from '../../storage.class';
import {FirebaseService} from '../../firebase.service';
import {Router} from '@angular/router';
import {catchError, map, tap} from 'rxjs/operators';
import {of, throwError} from 'rxjs';
import {AuthResponse} from '../../auth-response';

@Injectable()
export class AuthService {

    private isRefreshingToken = false;

    private loggedIn = new EventEmitter<boolean>();
    onLogin = this.loggedIn.asObservable();

    constructor(private apiService: ApiService,
                private router: Router,
                private firebaseService: FirebaseService) {
    }

    updateFirebaseToken(token) {
        return this.apiService.postCall$<boolean>('auth/firebase', {token});
    }

    loginEmailWithId(id) {
        return this.apiService.postCall$<boolean>('auth/login-email', {id});
    }

    loginEmail(email) {
        return this.apiService.postCall$<boolean>('auth/login-email', {email});
    }

    login(email, password) {
        return this.apiService.postCall$<AuthResponse>('auth/login', {email, password})
            .pipe(map(response => this.handleResponse(response.data as AuthResponse)));
    }

    refresh(token?: string) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            if (token) {
                return this.apiService.postCall$<AuthResponse>('auth/refresh', {token})
                    .pipe(map(response => this.handleResponse(response.data as AuthResponse)), catchError(error => {
                        this.isRefreshingToken = false;
                        return throwError(error);
                    }));
            }
            return this.apiService.getCall$<AuthResponse>('auth/refresh')
                .pipe(map(response => this.handleResponse(response.data)), catchError(error => {
                    this.isRefreshingToken = false;
                    return throwError(error);
                }));
        }
        return of();
    }

    logout() {
        this.firebaseService.unregisterFromAllTopics();
        return this.apiService.postCall$('auth/logout')
            .pipe(map(response => {
                this.loggedIn.emit(false);
                Storage.logoutUser();
                return response;
            }), catchError(error => {
                this.loggedIn.emit(false);
                Storage.logoutUser();
                return throwError(error);
            }));
    }

    public getUserDetails() {
        return this.apiService.getCall$<User>('auth/meNew').pipe(tap(user => {
            const oldUser = Storage.getUser();
            this.setUser(user.data);
            if (oldUser.group !== user.data.group) {
                this.loggedIn.emit(true);
            }
        }));
    }

    public requestRestorePassword(email) {
        return this.apiService.postCall$('auth/request-restore', {email: email});
    }

    public verifyResetToken(token: string): Promise<Object> {
        return this.apiService.getCall('auth/verify-reset-token', {token});
    }

    public restorePassword(token: string, password: string) {
        return this.apiService.postCall$<AuthResponse>('auth/restore', {password, token})
            .pipe(map(response => this.handleResponse(response.data as AuthResponse)));
    }

    public resetLoginTries(token: string): Promise<Object> {
        return this.apiService.postCall('auth/resettries', {token});
    }

    public setLocalstorage(user: User, accessTokenData: object) {
        Storage.setUserToken(accessTokenData['access_token']);
        Storage.setExpireTimeToken(accessTokenData['expires_in']);
        this.setUser(user);
    }

    private setUser(user: User) {
        Storage.setUser(user);

        if (user.towns?.length > 0) {
            user.towns.forEach(town => {
                this.firebaseService.subscribeToTopic('newsfromtown' + town.id);
            });
        }
        this.firebaseService.subscribeToTopic('my-workareas-' + user.id);
    }

    private handleResponse(authResponse: AuthResponse) {
        Storage.setUserToken(authResponse.access_token);
        Storage.setUser(authResponse.user);
        Storage.setExpireTimeToken(authResponse.expires_in);
        this.setUser(authResponse.user);
        this.firebaseService.subscribeToTopic('my-workareas-' + authResponse.user.id);
        this.loggedIn.emit(true);
        this.isRefreshingToken = false;
        return authResponse;
    }

}
