<div class="phases">
    <div (cdkDropListDropped)="drop($event, projectPhases)"
         cdkDropList
         [cdkDropListDisabled]="!Utils.userHasRights('ADMIN') || disableDragDrop">
        <div (click)="selectPhase(projectPhase)"
             *ngFor="let projectPhase of projectPhases" [class.selected]="projectPhase.id === selectedPhase?.id"
             cdkDrag
             class="phase">
            <div class="prefix">
                <div [class.current]="projectPhase.id === currentProjectPhase?.id"
                     [class.done]="projectPhase.done"
                     class="icon"></div>
                <button (click)="editPhase(projectPhase)" color="primary" mat-icon-button *ngIf="Utils.userHasRights('ADMIN')">
                    <mat-icon class="fa fa-edit"></mat-icon>
                </button>
            </div>
            <div class="item">
                {{projectPhase.name}}<br>
                <ng-container *ngIf="projectPhase.start_date">
                    Startdatum
                </ng-container>
                {{projectPhase.start_date | date : 'd MMMM yyyy'}}
            </div>
            <div class="suffix">
                <div class="icon"></div>
            </div>
        </div>
    </div>
    <div class="phase add" [class.empty]="projectPhases?.length < 1" *ngIf="Utils.userHasRights('ADMIN')">
        <div class="data">
            <button (click)="editPhase()" color="primary" mat-button>
                Projectfase toevoegen <img src="../../../assets/images/icons/townprojects/add.svg">
            </button>
        </div>
    </div>

</div>
