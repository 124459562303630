import {Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {TownService} from '../services/town/town.service';
import {Town} from '../town.class';
import {Storage} from '../storage.class';
import {Router} from '@angular/router';
import {Filter} from '../filter';
import {AuthService} from '../services/auth/auth.service';
import {Routenames} from '../route-names.enum';
import {SearchService} from '../services/search/search.service';
import {MatTooltip} from '@angular/material/tooltip';
import {Utils} from '../utils.class';

@Component({
    selector: 'app-towns-overview',
    templateUrl: './towns-overview.component.html',
    styleUrls: ['./towns-overview.component.scss']
})
export class TownsOverviewComponent implements OnInit {

    public towns: Town[] = [];

    public searchedTowns: Town[] = [];

    public search;

    type = "towns";

    Routenames = Routenames;

    @ViewChild("tooltip") tooltip: MatTooltip;

    constructor(private townService: TownService,
                private router: Router,
                private authService: AuthService,
                private searchService: SearchService) {
    }

    ngOnInit() {
        if (Utils.userIsGroup('CONSUMER')) {
            this.router.navigate([Routenames.timeline]);
        } else {
            this.townService.getList().then(towns => {
                this.towns = towns;
                this.searchedTowns = this.towns;
                if (this.towns.length === 1) {
                    const town = this.towns[0];
                    Storage.setTown(town);
                    this.router.navigate(['towns', town.id]);
                }
            });
            this.searchService.change.subscribe(searchValue => {
                this.search = searchValue;
                this.searching();
            });
        }
    }

    @HostListener('window:keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter' && this.searchedTowns.length === 1) {
            this.openTown(this.searchedTowns[0]);
            event.preventDefault();
        }
    }

    openTown(town) {
        Storage.setFilter(new Filter());
        if (town.consumerTown) {
            this.router.navigate(['']);
        } else {
            if ((town['hasTownprojects'] && town['hasTownprojects'] > 1) || town.id === 0) {
                this.router.navigate(['towns', town.id, 'projects']);
            } else if (town['hasTownprojects'] && town['maintenanceProjectId'] > 1) {
                this.router.navigate(['towns', town.id], {queryParams: {project: town['maintenanceProjectId']}});
            } else {
                this.router.navigate(['towns', town.id]);
            }
        }
    }

    searching() {
        if (!this.search) {
            this.searchedTowns = this.towns;
        } else {
            this.searchedTowns = [];
            this.towns.forEach(town => {
                if (town.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
                    this.searchedTowns.push(town);
                }
            });
        }
        if (this.searchedTowns.length === 1) {
            this.tooltip.show();
        } else {
            this.tooltip.hide();
        }
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['login']);
    }
}
