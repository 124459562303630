import {Component, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Settings} from '../settings.class';
import {MapLocation, Polygon, Pushpin} from '../angular-bing-maps/angular-bing-maps.component';
import {WorkareaService} from '../services/workarea/workarea.service';
import {TownService} from '../services/town/town.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Region} from '../region.class';
import {Storage} from '../storage.class';
import {ConfirmModalService} from '../shared/confirm-modal.service';
import {Utils} from '../utils.class';
import {Workarea} from '../workarea.class';
import {Town} from '../town.class';
import {CordovaService} from '../cordova.service';
import {WorkareasFilterComponent} from '../workareas-filter/workareas-filter.component';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {WorkareaProjectChooseDialogComponent} from './workarea-type-choose-dialog/workarea-project-choose-dialog.component';
import {Filter} from '../filter';

declare var cordova;

@Component({
    selector: 'app-workareas-map',
    templateUrl: './workareas-map.component.html',
    styleUrls: ['./workareas-map.component.scss']
})
export class WorkareasMapComponent implements OnInit, OnDestroy {

    public town = new Town();

    public polygons: Polygon[] = [];

    public pushpins: Pushpin[] = [];
    public Settings = Settings;
    public selectedWorkareas: Workarea[] = [];
    public mapGeoTrigger = 0;
    public mapZoomTrigger = 0;
    public mapStyleToggleTrigger = 0;
    public selectedPosition: any = {};
    public zoomChange = 0;
    public searchbarVisible = false;
    public filterbarVisible = false;
    public filterbarWasVisible = false;
    public mobileMenuOpen = false;
    public forceMapWidthFilter = false;
    public forceMapWidthSearch = false;
    public Utils = Utils;
    public currentCoords = {'coords': {'latitude': 0.000, 'longitude': 0.000}};
    public forceFollowLocation = false;
    public loading = false;
    public searchResults: Workarea[] = [];
    public workareas: Workarea[];
    public filteredWorkareas: Workarea[];
    public totalWorkareaCount = 0;
    public filteredWorkareaCount = 0;
    public appliedAnyFilter = false;
    public detailId = -1;
    screenshot = false;
    public customStyle = {};
    projectId;
    private townRegion: Region;
    private insideTown = true;
    @ViewChild('mapFilter', {static: true}) private filterRef: WorkareasFilterComponent;
    private scrollToWorkareaId: number;
    private filter = new Filter();
    private subscriptions = new Subscription();
    private searchTimeout;

    constructor(private workareaService: WorkareaService,
                private townService: TownService,
                private router: Router,
                private ngZone: NgZone,
                private route: ActivatedRoute,
                private dialog: MatDialog,
                private activatedRoute: ActivatedRoute,
                private cordovaService: CordovaService,
                private confirmModalService: ConfirmModalService) {


    }

    private _searchinput = '';

    public get searchinput() {
        return this._searchinput;
    }

    public set searchinput(val) {
        this._searchinput = val;
        this.loading = true;
        setTimeout(() => {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            if (this._searchinput == val) {
                this.searchResults = [];
                if (this._searchinput.length > 0) {
                    this.filteredWorkareas.forEach((workarea) => {
                        if (workarea.number.toString().indexOf(val) !== -1
                            || (workarea.street ?? '').toLowerCase().indexOf(val.toLowerCase()) !== -1
                            || (workarea.comments ?? '').toLowerCase().indexOf(val.toLowerCase()) !== -1) {
                            this.searchResults.push(workarea);
                        }
                    });
                }
                this.loading = false;
            }
        }, 300);
    }

    public searchToggle() {
        if (this.mobileMenuOpen) {
            this.mobileMenuOpen = false;
            this.mobileMenuOpen = false;
        }
        this.searchbarVisible = !this.searchbarVisible;
    }

    public filterToggle() {
        if (this.mobileMenuOpen) {
            this.mobileMenuOpen = false;
        }
        this.filterbarVisible = !this.filterbarVisible;
    }

    ngOnInit() {
        this.cordovaService.setBackbuttonAction(() => {
            this.ngZone.run(() => {
                this.router.navigate(['/towns']);
            });
        });
        this.subscriptions.add(this.filterRef.filterChange.subscribe((filter) => {
            this.filter = filter;
            this.filterWorkareas(filter);
        }));
        this.subscriptions.add(this.filterRef.closeFilter.subscribe(() => {

            this.filterbarVisible = false;
        }));
        const firstPart = this.route.snapshot.routeConfig.path.split('/')[0];
        this.screenshot = firstPart == 'towns-screenshot';
        if (this.screenshot) {
            this.mapStyleToggleTrigger++;
        }

        this.subscriptions.add(this.route.url.subscribe(() => {
            this.reload(true);
        }));
        this.subscriptions.add(this.route.queryParams.subscribe(params => {
            this.projectId = params['project'];
        }));

        this.forceFollowLocation = Storage.getUser().group === 'CONSUMER' && Storage.getUser()?.id !== 9; // not for apple...

        let town = Storage.getTown();
        this.town = town;

        const exteriorRing = [];

        town.area.forEach(cord => {
            const location = {
                latitude: +cord['latitude'],
                longitude: +cord['longitude']
            };
            exteriorRing.push(location);
        });

        const mapsPolygon = new Polygon({exteriorRing: exteriorRing});
        this.polygons.push(mapsPolygon);

        this.townRegion = new Region(exteriorRing);

        if (!this.screenshot) {
            this.centerTown();
        }


    }

    public centerWorkareas() {
        Settings.mapCenter = null;
    }

    public viewchangeendEvent(data) {

        this.selectedWorkareas = [];

        Settings.mapCenter = data.center;
        if (Settings.mapZoom !== data.zoomLevel) {
            Settings.mapZoom = data.zoomLevel;
        }

        this.insideTown = this.townRegion.insideTown(Settings.mapCenter);

    }

    public createWorkarea(isDocument = false) {

        if (!this.townRegion.insideTown(Settings.mapCenter)) {
            if (Storage.getTown().area_forced) {
                this.confirmModalService.showModal(
                    'Melding buiten werkgebied',
                    'Je kunt geen melding buiten het werkgebied aanmaken. ' +
                    'Om een melding te maken moet je je binnen het werkgebied bevinden.',
                    'Oké',
                    null
                );
            } else {
                this.confirmModalService.showModal(
                    'Opdracht buiten gemeente',
                    'Je probeert een opdracht te maken buiten je gemeente, weet je zeker dat je door wilt gaan?',
                    'Ja',
                    'Nee').then(() => {
                    this.chooseWorkareaType();
                }, () => {

                });
            }
        } else {
            this.chooseWorkareaType();

        }
    }

    public mousedownEvent(event) {
        this.selectedWorkareas = [];
    }

    public reload(reload = false) {
        this.pushpins = [];
        this.workareaService.getAll(reload).then(workareas => {
            this.workareas = workareas;
            this.totalWorkareaCount = this.workareas.length;
            this.filterWorkareas(this.filter);
        });
    }

    public centerTown() {
        Settings.mapZoom = this.townRegion.bestMapView(window.innerWidth, window.innerHeight, 5);
        Settings.mapCenter = this.townRegion.centroid();
    }

    public centerCountry() {
        const countryPoints = [
            {
                'latitude': 53.49785,
                'longitude': 4.682922
            }, {
                'latitude': 53.396432,
                'longitude': 7.393799
            }, {
                'latitude': 50.75731,
                'longitude': 6.011581
            }, {
                'latitude': 51.238706,
                'longitude': 3.269119
            }
        ];
        const region = new Region(countryPoints);
        Settings.mapZoom = region.bestMapView(window.innerWidth, window.innerHeight, 20);
        Settings.mapCenter = region.centroid();
    }

    public zoomIn() {
        this.zoomChange = +1;
        this.mapZoomTrigger++;
    }

    public zoomOut() {
        this.zoomChange = -1;
        this.mapZoomTrigger++;
    }

    public setMapCenterGeo() {

        this.mapGeoTrigger++;
        setTimeout(() => {
            Settings.mapZoom = 18;
        });
    }

    public mapStyleToggle() {
        this.mapStyleToggleTrigger++;
        this.reload();
    }

    public setZoom(event) {
        setTimeout(() => {
            Settings.mapZoom = event + this.zoomChange;
        });
    }

    public pushpinClick(event) {
        this.ngZone.run(() => {
            this.selectedWorkareas = [];

            /*
                Calculate ratio between all the workareas on the map,
                if workareas are close together show a detail preview box with those workarea details.
            */
            this.selectedWorkareas.push(event.pushpin.data);
            let clickedId = event.pushpin.data['id'];

            if (Settings.mapZoom > 8) {
                const latitudeRanges = {
                    20: 0.00002,
                    19: 0.00004,
                    18: 0.0001,
                    17: 0.0002,
                    16: 0.0004,
                    15: 0.0008,
                    14: 0.0015,
                    13: 0.003,
                    12: 0.006,
                    11: 0.012,
                    10: 0.025,
                    9: 0.05
                };
                const longitudeRanges = {
                    20: 0.00005,
                    19: 0.00008,
                    18: 0.0002,
                    17: 0.0004,
                    16: 0.001,
                    15: 0.002,
                    14: 0.004,
                    13: 0.008,
                    12: 0.02,
                    11: 0.04,
                    10: 0.08,
                    9: 0.15
                };

                const latitudeRange = latitudeRanges[Math.round(Settings.mapZoom)];
                const longitudeRange = longitudeRanges[Math.round(Settings.mapZoom)];

                this.pushpins.forEach((pushpin) => {
                    if (pushpin.data['id'] !== clickedId) {

                        let calculatedLatitudeDifference = ((pushpin.latitude - event.pushpin.latitude));
                        let calculatedLongitudeDifference = ((pushpin.longitude - event.pushpin.longitude));

                        calculatedLatitudeDifference = +Math.abs(calculatedLatitudeDifference).toFixed(5);
                        calculatedLongitudeDifference = +Math.abs(calculatedLongitudeDifference).toFixed(5);

                        if (calculatedLatitudeDifference < latitudeRange
                            && calculatedLongitudeDifference < longitudeRange) {
                            this.selectedWorkareas.push(pushpin.data);
                        }
                    }
                });
            }


            let top = event.event.pageY + 10;
            let left = event.event.pageX + 25;
            let right = 'auto';
            let bottom = 'auto';
            const screenHeight = document.body.scrollHeight;
            let screenWidth = document.body.scrollWidth;
            if (this.searchbarVisible) {
                screenWidth = screenWidth * 0.7;
            }
            let maxHeight = 'calc(100% - ' + (event.event.pageY + 10) + 'px)';

            if (top > screenHeight / 2) {
                bottom = ((event.event.pageY - screenHeight) * -1) + 20 + 'px';
                top = 'auto';
                maxHeight = (event.event.pageY - 20) + 'px';
            } else {
                top = top + 'px';
            }

            if (left > (screenWidth - 350)) {
                left = 'auto';
                right = (screenWidth - event.event.pageX + 25) + 'px';
            } else {
                left = left + 'px';
            }

            this.selectedPosition = {
                left: left,
                right: right,
                top: top,
                bottom: bottom,
                maxHeight: maxHeight
            };
        });
    }

    goHome() {
        if (this.town.consumerTown) {
            this.router.navigate([``]);
        } else {
            this.router.navigate([`towns`]);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private chooseWorkareaType() {
        const dialogRef = this.dialog.open(WorkareaProjectChooseDialogComponent, {
            panelClass: 'confirm-dialog',
            data: {}
        });
        const subs = dialogRef.afterClosed().subscribe((type) => {
            if (type) {
                const route = ['towns', this.town.id, 'workareas', -1] as (number | string | object)[];
                const params = {project: this.activatedRoute.snapshot.queryParams['project']};
                if (type === 'documents') {
                    route.push('documents');
                }
                params['type'] = type;
                this.router.navigate(route, {
                    queryParams: params,
                    queryParamsHandling: 'merge'
                });
            }
            subs.unsubscribe();
        });
    }

    private filterWorkareas(filter: Filter) {
        const loadedPushpins = [];
        this.appliedAnyFilter = false;
        if (this.workareas) {
            this.filteredWorkareas = filter.apply(this.workareas);

            this.filteredWorkareas.forEach(workarea => {

                if (this.mapStyleToggleTrigger % 2 != 0 && workarea.type === 'drillings') {
                    workarea.status_colorcode = 'light';
                }

                loadedPushpins.push(new Pushpin({
                    longitude: workarea.lng,
                    latitude: workarea.lat,
                    icon: Settings.getMarker(
                        workarea.id,
                        workarea.number,
                        workarea.status_colorcode,
                        (this.filteredWorkareas.length < 300 || Settings.mapSettings['largeMapTextShadow']),
                        workarea.has_documents,
                        workarea.type),
                    data: workarea
                }));
            });

            this.filteredWorkareaCount = this.filteredWorkareas.length;
            this.pushpins = loadedPushpins;
            this.scrollToWorkarea();
            if (this.screenshot) {
                this.centerWorkareas();
            }
        }

    }

    private scrollToWorkarea(workareaId?: number) {
        if (!workareaId) {
            workareaId = this.scrollToWorkareaId;
        }
        if (workareaId) {
            if (this.pushpins.length > 0) {
                this.pushpins.forEach(workarea => {

                    if (workarea.data['id'] === workareaId) {
                        Settings.mapCenter = new MapLocation({
                            latitude: workarea.latitude,
                            longitude: workarea.longitude
                        });

                        Settings.mapZoom = 18 + Math.random();

                        if (window.innerWidth <= 767) {
                            this.searchbarVisible = false;
                        }
                    }
                });
            } else {
                this.scrollToWorkareaId = workareaId;
            }
        }

    }

}
