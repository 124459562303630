<section *ngIf="timeline" class="detail">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-9">
                <app-codalt-image [path]="timeline.id + '/' + timeline.image"
                                  [thumb]="timeline.image_thumb"
                                  [adapt]="timeline.adapt_image"
                                  secureType="timeline"
                                  class="detail__image">
                </app-codalt-image>
                <div class="detail__block pb-0">
                    <h1 class="detail__title">
                        {{timeline.title}}
                    </h1>
                    <div class="detail__details">
                        <div class="detail__author">
                            {{timeline?.town_project?.name}} - {{timeline.user.name}}
                        </div>
                        <time class="detail__date">
                            {{timeline.publish_date | date:'EEEE d MMMM yyyy HH:mm'}}
                        </time>
                    </div>

                    <div class="content">
                        <p [innerHTML]="timeline.content"></p>
                    </div>

                    <div class="item-images">
                        <app-codalt-image (click)="openImage(image)"
                                          *ngFor="let image of timeline.images"
                                          [path]="timeline.id + '/' + image.path"
                                          [adapt]="image.adapt_image"
                                          secureType="timeline"
                                          [thumb]="image.thumb">
                        </app-codalt-image>
                    </div>
                    <div class="item-documents" *ngIf="timeline.documents?.length">
                        <b>Bijlage(n): </b>
                        <table>
                            <tr (click)="openDocument(document)" *ngFor="let document of timeline.documents">
                                <td>
                                    <i class="fas fa-file-pdf-o"></i>
                                </td>
                                <td>
                                    {{document.path}}
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="timeline.readBy" class="col-12 col-lg-3">
                <div class="detail__block readby">
                    <h3 class="mb-2">Gelezen ({{timeline.readBy.length || '0'}})</h3>
                    <div *ngFor="let user of timeline.readBy">
                        {{user.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="Utils.userHasRights('ADMIN')" class="floating-buttons">
        <button (click)="delete()" color="primary" mat-fab>
            <mat-icon class="fas fa-trash"></mat-icon>
        </button>
        <button color="primary" mat-fab
                routerLink="/towns/{{timeline?.town_project?.town_id}}/projects/{{timeline?.townproject_id}}/{{Routenames.timeline}}/{{timeline.slug}}/kopie">
            <mat-icon class="fas fa-copy"></mat-icon>
        </button>
        <button color="primary" mat-fab
                routerLink="/towns/{{timeline?.town_project?.town_id}}/projects/{{timeline?.townproject_id}}/{{Routenames.timeline}}/{{timeline.slug}}/bewerken">
            <mat-icon class="fas fa-pencil-alt"></mat-icon>
        </button>
    </div>
</section>
