import {Component, OnInit} from '@angular/core';
import {User} from '../../user.class';
import {UserService} from '../../services/user/user.service';
import {Router} from '@angular/router';
import {Storage} from '../../storage.class';
import {ConfirmModalService} from '../../shared/confirm-modal.service';
import {Utils} from '../../utils.class';
import {SearchService} from '../../services/search/search.service';

@Component({
    selector: 'user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

    public users: User[];
    public searchedUsers: User[];

    public search;

    public loggedInUser: User;

    public Utils = Utils;

    showConsumers = false;

    userGroupMap = new Map<string, string>([
        ['READ', 'Alleen lezen'],
        ['CONSUMER', 'Consument'],
        ['CONTRACTOR', 'Aannemer'],
        ['GEMEENTE', 'Gemeente'],
        ['SUBADMIN', 'Gemeente Admin'],
        ['ADMIN', 'Admin']
    ]);

    constructor(private userService: UserService,
                private searchService: SearchService,
                private router: Router,
                private confirmModal: ConfirmModalService) {


    }

    ngOnInit() {
        this.getUsers();
        this.searchService.change.subscribe(searchValue => {
            this.search = searchValue;
            this.searching();
        });
    }

    showUser(userId: number) {
        this.router.navigate([`users/${userId}`]);
    }

    newUser() {
        this.router.navigate(['users/new']);
    }

    revokeAccess(user: User) {
        this.confirmModal.showModal('Verwijder gebruiker', 'Weet je zeker dat je deze gebruiker wilt verwijderen?', 'Verwijder')
            .then(() => {
                this.userService.deleteUser(user.id).then(response => {
                    this.getUsers();
                }, error => {
                });
            }, () => {
            });
    }

    searching() {
        if (!this.search) {
            this.searchedUsers = this.users.filter(p => p.group !== 'CONSUMER' || this.showConsumers);
        } else {
            this.searchedUsers = [];
            this.users.filter(p => p.group !== 'CONSUMER' || this.showConsumers).forEach(user => {
                if (user.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1 || user.towns.find(t => t.code.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)) {
                    this.searchedUsers.push(user);
                }
            });
        }
    }

    private getUsers() {
        this.loggedInUser = Storage.getUser();
        this.userService.getUsers().then((users) => {
            this.users = users;
            this.searching();
        }, () => {
        });
    }

}
