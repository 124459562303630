import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {Article} from '../classes/article.class';
import {ArticleImage} from '../classes/article-image.class';
import {ArticleDocument} from '../classes/article-document';

@Injectable()
export class ArticleService {

    constructor(private apiService: ApiService) {
    }

    getList(limit?: number, skip?: number, future?: boolean, townprojectIds?: number[]) {
        future = future || false;
        return this.apiService.getCall$<Article[]>(`articles/${limit}/${skip}`, {
            future, townprojectIds
        });
    }

    get(slug: string, copy?: boolean) {
        const params = copy ? {copy} : {};
        return this.apiService.getCall$<Article>(`articles/${slug}`, params);
    }

    save(article: Article) {
        return this.apiService.postCall$<Article>('articles', article);
    }

    delete(id: number) {
        return this.apiService.deleteCall$(`articles/${id}`);
    }

    reserve() {
        return this.apiService.postCall$<Article>('articles/reserve');
    }

    addImage(id, path) {
        return this.apiService.postCall$<ArticleImage>('articles/add-image', {id, path});
    }

    sortImages(article: Article) {
        return this.apiService.postCall$<ArticleImage>('articles/sort-images', {
            id: article.id,
            images: article.images.map(p => {
                return {
                    id: p.id,
                    adapt_image: p.adapt_image
                };
            })
        });
    }

    rmImage(id) {
        return this.apiService.deleteCall$(`articles/rm-image/${id}`);
    }

    addDocument(id, path) {
        return this.apiService.postCall$<ArticleDocument>('articles/add-document', {id, path});
    }

    sortDocuments(article: Article) {
        return this.apiService.postCall$<ArticleDocument>('articles/sort-documents', {
            id: article.id,
            documents: article.documents.map(p => p.id)
        });
    }

    rmDocument(id) {
        return this.apiService.deleteCall$(`articles/rm-document/${id}`);
    }
}
