<div [class.opened]="mobileMenuOpen" class="mobile-menu user-select-none">
    <ul class="list-group">
        <li class="list-group-item">
            Menu <span (click)="mobileMenuOpen = !mobileMenuOpen" class="icon-close_white"></span>
        </li>
        <li (click)="goHome()" class="list-group-item">
            <div class="icon-home"></div>
            Home
        </li>
        <li class="list-group-item">
            <div class="icon-search"></div>
            Zoeken
        </li>
        <li [routerLink]="['/settings', {via: 'towns/'+town.id}]" class="list-group-item">
            <div class="icon-settings"></div>
            Instellingen
        </li>
    </ul>
</div>
<div class="navbar-mobile user-select-none">
    <i (click)="mobileMenuOpen = !mobileMenuOpen" class="fas fa-bars"></i> <span>{{town.name}}</span>
</div>
<div class="town-details">
    <div class="content" *ngIf="!selectedCustomer">
        <h4>Klik op een gebied op de kaart</h4>
    </div>
    <div class="content" *ngIf="selectedCustomer">
        <h4>{{selectedCustomer?.fields.Naam}}</h4>
        <h5>{{selectedCustomer.fields.Status}}</h5>
        <div class="icons">
            <div class="icon"><i class="fa fa-road"></i><span>{{selectedCustomer.fields["Weglengte in KM"] ?? '-'}} km </span></div>
            <div class="icon"><i class="fa fa-truck"></i><span>{{selectedCustomer.fields["Afstand vanaf Utrecht"] ?? '-'}} km </span></div>
            <div class="icon"><i class="fa fa-file-contract"></i><span>{{selectedCustomer.fields["Aanbestedingswijze"] ?? '-'}} </span></div>
            <div class="icon"><i class="fa fa-calendar-times"></i><span>{{selectedCustomer.fields['Resterende Looptijd Einde Contractduur'] ?? 'Onbekend'}} maanden </span></div>
            <div class="icon"><i class="fa fa-euro-sign"></i><span>{{(selectedCustomer.fields['Omzet per jaar'] | number : '0.0-0') ?? '-'}} </span></div>
            <div class="icon"><i class="fa fa-comments"></i><span>{{selectedCustomer.fields['Opmerkingen Diane']?.length > 3 ? 'Ja' : 'Nee'}} </span></div>
        </div>
        <div class="text">
            <b>Opmerkingen Diane</b><br>
            <div [innerHTML]="selectedCustomer.fields['Opmerkingen Diane'] ?? '-' | nl2br"></div>

        </div>
    </div>
</div>
<div [class.bar-visible]="searchbarVisible"
     [class.filter-visible]="filterbarVisible"
     [class.filter-was-visible]="filterbarWasVisible"
     [class.force-map-width-filter]="forceMapWidthFilter"
     [class.force-map-width-search]="forceMapWidthSearch"
     *ngIf="town"
     class="near-searchbar user-select-none town-details-visible">
    <div class="logo-small float-right" routerLink="/"></div>
    <div class="loading" *ngIf="polygons?.length === 0">
        De kaart wordt geladen...
    </div>
    <app-angular-bing-maps
        (map-zoom)="setZoom($event)"
        *ngIf="polygons?.length > 0"
        (polygon-click)="polygonClick($event)"
        (viewchangeend)="viewchangeendEvent($event)"
        [bing-maps-api-key]="Settings.BING_MAPS_API_KEY"
        [customStyle]="customStyle"
        [showCurrentLocation]="false"
        [alwaysShowFill]="true"
        [geoData]="currentCoords"
        [map-geo-trigger]="mapGeoTrigger"
        [map-style-toggle-trigger]="mapStyleToggleTrigger"
        [map-zoom-trigger]="mapZoomTrigger"
        [screenshot]="screenshot"
        [polygons]="polygons"
        [pushpins]="pushpins"
        [set-map-center]="Settings.mapCenter"
        [set-map-zoom]="Settings.mapZoom"
        id="map"
    ></app-angular-bing-maps>


    <div [style.bottom]="selectedPosition.bottom"
         [style.left]="selectedPosition.left"
         [style.max-height]="selectedPosition.maxHeight"
         [style.right]="selectedPosition.right"
         [style.top]="selectedPosition.top"
         class="selected-workareas user-select-none">
        <a *ngFor="let workarea of selectedWorkareas"
           class="workarea"
           routerLink="/towns/{{town.id}}/workareas/{{workarea.id}}{{workarea.has_documents ? '/documents' : null}}">
            <div class="details">
                <div class="number">
                    {{workarea.has_documents ? workarea.street : workarea.number}}
                    <i class="icon-more-custom pointer" routerLink="/workarea"></i>
                </div>
                <table border="0" cellpadding="0" cellspacing="0">
                    <tr>
                        <td class="txtbold">Soort</td>
                        <td width="10"></td>
                        <td *ngIf="workarea.extra && workarea.extra.vogzang_type">{{workarea.extra.vogzang_type}}
                            <small *ngIf="workarea.extra && workarea.extra.vogzang_service">
                                ({{workarea.extra.vogzang_service}})
                            </small>
                        </td>
                        <td *ngIf="workarea.extra && workarea.extra.damage">
                            {{workarea.extra.damage}}
                        </td>
                        <td *ngIf="workarea.has_documents">
                            Werklocatie
                        </td>
                    </tr>
                    <tr>
                        <td class="txtbold">Status</td>
                        <td width="10"></td>
                        <td>{{workarea.status_name}}</td>
                    </tr>
                </table>
            </div>
            <div *ngIf="!workarea.has_documents" [style.background-image]="workarea.image + '?tinythumb=true' | safeBgUrl"
                 class="image"></div>
        </a>
    </div>
</div>
