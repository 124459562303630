import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {NewsOverviewComponent} from './news/news-overview/news-overview.component';
import {NewsDetailComponent} from './news/news-detail/news-detail.component';
import {NewsEditComponent} from './news/news-edit/news-edit.component';
import {SettingsComponent} from './settings/settings.component';
import {RequestRestorePasswordComponent} from './request-restore-password/request-restore-password.component';
import {RestorePasswordComponent} from './restore-password/restore-password.component';
import {ContactOverviewComponent} from './contacts/contact-overview/contact-overview.component';
import {ContactDetailComponent} from './contacts/contact-detail/contact-detail.component';
import {ContactEditComponent} from './contacts/contact-edit/contact-edit.component';
import {RegisterComponent} from './register/register.component';
import {ConfirmEmailComponent} from './confirm-email/confirm-email.component';
import {ChangePasswordComponent} from './user/change-password/change-password.component';
import {TownsOverviewComponent} from './towns-overview/towns-overview.component';
import {UserManagementComponent} from './user-management/user-management.component';
import {UserDetailComponent} from './user-management/user-detail/user-detail.component';
import {ResetLoginTriesComponent} from './reset-login-tries/reset-login-tries.component';
import {TownProjectOverviewComponent} from './town-project-overview/town-project-overview.component';
import {TownDetailComponent} from './town-detail/town-detail.component';
import {TownProjectDetailComponent} from './town-project-detail/town-project-detail.component';
import {WorkareaComponent} from './workarea/workarea.component';
import {DocumentsComponent} from './documents/documents.component';
import {TownListComponent} from './town-list/town-list.component';
import {LogoutComponent} from './logout/logout.component';
import {TownTableComponent} from './town-table/town-table.component';
import {TownReportComponent} from './town-report/town-report.component';
import {ProjectsComponent} from './projects/projects.component';
import {ChatComponent} from './chat/chat/chat.component';
import {Routenames} from './route-names.enum';
import {ArticlesComponent} from './articles/articles.component';
import {ArticleDetailComponent} from './articles/article-detail/article-detail.component';
import {CanDeactivateForm} from './can-deactivate-form';
import {ArticleEditComponent} from './articles/article-edit/article-edit.component';
import {TimelineTownComponent} from './timeline/timeline-town/timeline-town.component';
import {TimelineDetailComponent} from './timeline/timeline-detail/timeline-detail.component';
import {TimelineEditComponent} from './timeline/timeline-edit/timeline-edit.component';
import {QrComponent} from './qr/qr.component';
import {AirtableMapComponent} from './airtable-map/airtable-map.component';
import {TimelineComponent} from './timeline/timeline/timeline.component';
import {SurveyEditComponent} from './survey/survey-edit/survey-edit.component';
import {SurveyAnswersComponent} from './survey/survey-answers/survey-answers.component';

const routes: Routes = [
    {path: '', pathMatch: 'full', component: DashboardComponent},
    {path: 'towns', pathMatch: 'full', component: TownsOverviewComponent},
    {path: Routenames.qr, pathMatch: 'full', component: QrComponent},
    {path: Routenames.projects, pathMatch: 'full', component: ProjectsComponent},
    {path: 'login', component: LoginComponent},
    {path: 'login/:token', component: LoginComponent},
    {path: 'register/:townId/:projectId', component: RegisterComponent},
    {path: 'user/change-password', component: ChangePasswordComponent},
    {path: 'request-restore-password', component: RequestRestorePasswordComponent},
    {path: 'forgot-password', component: RequestRestorePasswordComponent},
    {path: 'restore-password/:token', component: RestorePasswordComponent},
    {path: 'reset-login-tries/:token', component: ResetLoginTriesComponent},
    {
        path: 'towns-screenshot/:townId', component: TownDetailComponent,
        children: [
            {path: 'list', component: TownListComponent},
            {path: 'table', component: TownTableComponent},
            {path: 'report', component: TownReportComponent}
        ]
    },
    {
        path: 'towns-screenshot/:townId/:token', component: TownDetailComponent,
        children: [
            {path: 'list', component: TownListComponent},
            {path: 'table', component: TownTableComponent},
            {path: 'report', component: TownReportComponent}
        ]
    },
    {path: 'towns/:townId/projects/contacts', pathMatch: 'full', component: ContactOverviewComponent},
    {
        path: 'towns/:townId', pathMatch: 'prefix', component: TownDetailComponent,
        children: [
            {path: Routenames.projects, component: TownProjectOverviewComponent},
            {path: Routenames.projects + '/:projectId', component: TownProjectDetailComponent},
            {path: 'workareas/:workareaId/documents', component: DocumentsComponent},
            {path: 'workareas/:workareaId', component: WorkareaComponent},
            {path: Routenames.projectsDrilling, component: TownProjectOverviewComponent},
            {path: Routenames.projectsDrilling + '/:projectId', component: TownProjectDetailComponent}
        ]
    },

    {path: 'towns/:townId/projects/:projectId/contacts', pathMatch: 'full', component: ContactOverviewComponent},
    {path: 'towns/:townId/projects/:projectId/contacts/:id', pathMatch: 'full', component: ContactDetailComponent},
    {path: 'towns/:townId/projects/:projectId/contacts/edit/:id', pathMatch: 'full', component: ContactEditComponent},

    {path: 'news', pathMatch: 'full', component: NewsOverviewComponent},
    {path: 'news/:id', pathMatch: 'full', component: NewsDetailComponent},
    {path: 'news/edit/:id', pathMatch: 'full', component: NewsEditComponent},
    {path: Routenames.settings, pathMatch: 'full', component: SettingsComponent},
    {path: Routenames.settings + '/:via', pathMatch: 'full', component: SettingsComponent},

    {path: 'confirm-email', pathMatch: 'full', component: ConfirmEmailComponent},

    {path: Routenames.airtable, component: AirtableMapComponent},

    {path: Routenames.chat, component: ChatComponent},
    {path: Routenames.chat + '/:id', component: ChatComponent},
    {
        path: Routenames.articles, component: ArticlesComponent, children: [
            {path: 'toekomst', component: ArticlesComponent},
            {path: ':id', component: ArticleDetailComponent},
            {path: ':id/bewerken', component: ArticleEditComponent, canDeactivate: [CanDeactivateForm]},
            {path: ':id/kopie', component: ArticleEditComponent, canDeactivate: [CanDeactivateForm]}
        ]
    },
    {path: Routenames.articles + '/:id/vragenlijst-bewerken', component: SurveyEditComponent, canDeactivate: [CanDeactivateForm]},
    {path: Routenames.articles + '/:id/vragenlijst-antwoorden', component: SurveyAnswersComponent},


    {path: Routenames.timeline, pathMatch: 'full', component: TimelineComponent},
    {
        path: Routenames.timeline, component: TimelineTownComponent, children: [
            {path: ':id', component: TimelineDetailComponent},
            {path: ':id/bewerken', component: TimelineEditComponent, canDeactivate: [CanDeactivateForm]},
            {path: ':id/kopie', component: TimelineEditComponent, canDeactivate: [CanDeactivateForm]}
        ]
    },
    {
        path: 'towns/:townId/projects/:projectId/' + Routenames.timeline, component: TimelineTownComponent, children: [
            {path: ':id', component: TimelineDetailComponent},
            {path: ':id/bewerken', component: TimelineEditComponent, canDeactivate: [CanDeactivateForm]},
            {path: ':id/kopie', component: TimelineEditComponent, canDeactivate: [CanDeactivateForm]}
        ]
    },
    {path: 'users', pathMatch: 'full', component: UserManagementComponent},
    {path: 'users/:id', pathMatch: 'full', component: UserDetailComponent},
    {path: 'logout', pathMatch: 'full', component: LogoutComponent}
];

export const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always',
    relativeLinkResolution: 'legacy'
};

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes, routingConfiguration)]
})
export class AppRoutingModule {
}
