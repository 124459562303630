<form #workareaForm="ngForm">
    <div class="row">
        <div class="col-12 col-md-4">
            <mat-form-field class="w-100">
                <mat-select #type="ngModel" (valueChange)="inputChanged($event)"
                            [(ngModel)]="workarea.extra.damage"
                            errorState="type.errors?.required && (type.dirty || workareaForm.submitted)"
                            name="type"
                            placeholder="Type" required>
                    <mat-option [value]="undefined" disabled selected>Type</mat-option>
                    <mat-option *ngFor="let type of fieldOptions['damage']"
                                [value]="type">{{type}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-4">
            <mat-form-field class="w-100">
                <mat-select #location_damage="ngModel" (valueChange)="inputChanged($event)"
                            [(ngModel)]="workarea.extra.location_damage"
                            errorState="location_damage.errors?.required && (location_damage.dirty || workareaForm.submitted)"
                            name="location_damage"
                            placeholder="Schade Locatie" required>
                    <mat-option [value]="undefined" disabled selected>Schade Locatie</mat-option>
                    <mat-option *ngFor="let type of fieldOptions['location_damage']"
                                [value]="type">{{type}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="Utils.userHasRights('GEMEENTE')" class="col-12 col-md-4">
            <mat-form-field class="w-100">
                <mat-select (ngModelChange)="statusChange($event)" [(ngModel)]="workarea.status_id"
                            name="status"
                            placeholder="Status">
                    <mat-option *ngFor="let status of statuses" [value]="status.id" [disabled]="(status | statusDisabled : workarea.extra.waymark : workarea.status_id)">
                        {{status.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <mat-form-field class="w-100">
                <textarea [(ngModel)]="workarea.comments" cdkAutosizeMinRows="4"
                          cdkTextareaAutosize matInput name="comments"
                          placeholder="Opmerkingen"></textarea>
            </mat-form-field>
        </div>
    </div>
    <mat-divider class="pb-4 pt-2"></mat-divider>
    <div *ngIf="Utils.userHasRights('GEMEENTE')" class="row">
        <div class="col-12 col-md-6">
            <div class="row">
                <div *ngIf="fieldOptions['toplayer']" class="col-12">
                    <mat-form-field class="w-100">
                        <mat-select (selectionChange)="toplayerChange($event)"
                                    [(ngModel)]="workarea.extra.toplayer"
                                    [required]="workarea.extra.damage !== 'Scheuren'"
                                    name="toplayer"
                                    placeholder="Toplaag">
                            <mat-option disabled>Toplaag</mat-option>
                            <mat-option
                                value="{{fieldOptions['toplayer'].indexOf(workarea.extra.toplayer) === -1 ? workarea.extra.toplayer : ''}}">
                                Anders namelijk
                            </mat-option>
                            <mat-option *ngFor="let item of fieldOptions['toplayer']" [value]="item">
                                {{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div
                    *ngIf="(workarea.extra.toplayer === '' || workarea.extra.toplayer?.length > 0) && (fieldOptions['toplayer'] && fieldOptions['toplayer'].indexOf(workarea.extra.toplayer) === -1)"
                    class="col-12">
                    <mat-form-field class="w-100">
                        <input [(ngModel)]="workarea.extra.toplayer" matInput name="toplayer"
                               placeholder="Beschrijving toplaag" required
                               type="text">
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="workarea.extra.interlayer" name="interlayer"
                                    placeholder="Tussenlaag">
                            <mat-option disabled>Tussenlaag</mat-option>
                            <mat-option *ngFor="let item of fieldOptions['interlayer']" [value]="item">
                                {{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="workarea.extra.bottomlayer"
                                    [required]="workarea.extra.klinkerfloor"
                                    name="bottomlayer"
                                    placeholder="Onderlaag">
                            <mat-option disabled>Onderlaag</mat-option>
                            <mat-option *ngFor="let item of fieldOptions['bottomlayer']" [value]="item">
                                {{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="workarea.extra.execution" name="execution"
                                    placeholder="Uitvoering" required>
                            <mat-option disabled>Uitvoering</mat-option>
                            <mat-option *ngFor="let item of fieldOptions['execution']" [value]="item">
                                {{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-100">
                        <mat-select [(ngModel)]="workarea.extra.genpriority" name="genpriority"
                                    placeholder="Prioriteit">
                            <mat-option disabled>Prioriteit</mat-option>
                            <mat-option *ngFor="let item of fieldOptions['genpriority']" [value]="item">
                                {{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="pb-3 pt-3">
                        <mat-checkbox (ngModelChange)="changeKlinkerfloor($event)"
                                      [(ngModel)]="workarea.extra.klinkerfloor" color="primary"
                                      name="klinkerfloor">
                            Betreft klinkervak
                        </mat-checkbox>
                    </div>
                    <div>
                        <mat-checkbox [(ngModel)]="workarea.extra.reinforcementgrid" color="primary"
                                      name="reinforcementgrid">
                            Bewapeningsnet gebruiken
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
                <mat-select [(ngModel)]="workarea.extra.waymark" name="waymark"
                            placeholder="Wegmarkering">
                    <mat-option disabled>Wegmarkering</mat-option>
                    <mat-option *ngFor="let item of fieldOptions['waymark']" [value]="item">
                        {{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-6">
            <mat-form-field class="w-100">
                <mat-select [(ngModel)]="workarea.extra.roadblock"
                            name="roadblock"
                            placeholder="Afzetting"
                            [required]="workarea.extra.damage !== 'Scheuren'">
                    <mat-option disabled>Afzetting</mat-option>
                    <mat-option *ngFor="let item of fieldOptions['roadblock']" [value]="item">
                        {{item}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <mat-divider class="pb-4 pt-2"></mat-divider>
    <div *ngFor="let area of workarea.areas; let i = index" class="row pt-2">
        <div [class.col-6]="workarea.extra.damage == 'Scheuren'" class="col-3 col-md-3">
            <mat-form-field class="w-100">
                <input [(ngModel)]="area.length" matInput name="length{{i}}" placeholder="Lengte (m)" step="0.01"
                       type="text" appDecimalInput [decimalInput]="2">
            </mat-form-field>
        </div>
        <div *ngIf="workarea.extra.damage != 'Scheuren'" class="col-3 col-md-3">
            <mat-form-field class="w-100">
                <input [(ngModel)]="area.width" matInput name="width{{i}}" placeholder="Breedte (m)" step="0.01"
                       type="text" appDecimalInput [decimalInput]="2">
            </mat-form-field>
        </div>
        <div class="col-6 col-md-6 d-flex">
            <mat-form-field class="spacer" style="overflow: hidden">
                <input disabled matInput
                       placeholder="Oppervlakte {{ workarea.extra.damage == 'Scheuren' ? '(m)' : '(m2)' }}"
                       type="text" value="{{(area.width * area.length) | number: '.2' }}">
            </mat-form-field>

            <button (click)="removeArea(area)" color="warn" mat-icon-button>
                <mat-icon class="fa fa-remove" style="font-size: 24px;"></mat-icon>
            </button>
        </div>
    </div>
    <div class="row pb-5">
        <div *ngIf="!Utils.userIsGroup('READ')" class="col-6">
            <button (click)="addArea()" class="w-100" color="primary" mat-flat-button>Vlak toevoegen</button>
        </div>
        <div class="col-6">


            <mat-form-field class="spacer d-flex">
                <input disabled matInput
                       placeholder="Totaal oppervlakte {{ workarea.extra.damage == 'Scheuren' ? '(m)' : '(m2)' }}"
                       type="text"
                       value="{{areasTotal(workarea.areas)}}">
            </mat-form-field>
        </div>
    </div>
</form>
