import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormArray, FormGroup, Validators} from '@angular/forms';
import {CodaltComponent} from '../../codalt.component';
import {ArticleService} from '../../services/article.service';
import {SurveyAnswer} from '../../classes/surveyanswer.class';
import {SurveyService} from '../../services/survey.service';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {Routenames} from '../../route-names.enum';
import {SurveyQuestion} from '../../classes/surveyquestion.class';
import {Storage} from '../../storage.class';
import {Article} from '../../classes/article.class';
import {Color, ScaleType} from '@swimlane/ngx-charts';
import {ShortNumberPipe} from '../../pipes/short-number.pipe';

@Component({
    selector: 'app-survey-answers',
    templateUrl: './survey-answers.component.html',
    styleUrls: ['./survey-answers.component.scss']
})
export class SurveyAnswersComponent extends CodaltComponent implements OnInit {

    typeArticle = false;
    typeName = '';
    saving = false;
    typeObj: Article;
    typeService: ArticleService;
    faQuestions = new FormArray([], Validators.required);
    fc = {
        questions: this.faQuestions
    };
    form = new FormGroup(this.fc);
    answers: SurveyAnswer[];
    answersPerQuestion: Map<string, {}>;

    questionGraphs: QuestionGraph[];

    colorScheme: Color = {
        selectable: false,
        name: '',
        group: ScaleType.Ordinal,
        domain: [
            '#0F0064',
            '#85D1E4',
            '#262d33',
            '#8199ab',
            '#f2f4f6'
        ]
    };

    view: any[] = [700, 300];

    constructor(private router: Router,
                private route: ActivatedRoute,
                private articleService: ArticleService,
                private surveyService: SurveyService,
                public shortNumberPipe: ShortNumberPipe,
                private confirmDialogService: ConfirmDialogService) {
        super();
    }

    ngOnInit(): void {
        const firstPart = this.route.snapshot.url[0].path;
        this.typeName = firstPart;
        if (firstPart === Routenames.articles) {
            this.typeArticle = true;
            this.typeService = this.articleService;
        }

        this.subscriptions.add(this.typeService.get(this.route.snapshot.url[1].path).subscribe((typeObj) => {
            this.typeObj = typeObj.data;

            if (!this.typeObj.survey || this.typeObj.survey?.questions?.length === 0) {
                this.router.navigate([this.typeName, this.typeObj.slug, 'vragenlijst-bewerken'], {replaceUrl: true});
            } else {
                this.subscriptions.add(this.surveyService.getanswers(this.typeObj.survey.id).subscribe(answers => {
                    this.answers = answers.data;
                    this.answersPerQuestion = new Map();
                    this.typeObj.survey.questions.forEach(question => {
                        question.answers = {};
                        if (question.type === 'open') {
                            question.answersUsers = [];
                        } else {
                            question.answersUsers = {};
                        }

                        this.answers.forEach(surveyAnswer => {
                            const givenAnswer = surveyAnswer.answers[question.hash];
                            if (givenAnswer) {
                                if (Array.isArray(question.answersUsers)) {
                                    question.answersUsers.push(surveyAnswer.user?.id);
                                } else if (typeof question.answersUsers === 'object') {
                                    if (!question.answersUsers[givenAnswer]) {
                                        question.answersUsers[givenAnswer] = [];
                                    }
                                    question.answersUsers[givenAnswer].push(surveyAnswer.user?.id);
                                }

                                if (typeof givenAnswer === 'object') {
                                    question.options.forEach(option => {
                                        question.answers[option] = question.answers[option] || [];
                                        if (!!givenAnswer[option]) {
                                            question.answers[option].push(surveyAnswer.user.name);
                                        }
                                    });
                                } else {
                                    if (!question.answers[givenAnswer]) {
                                        question.answers[givenAnswer] = [];
                                    }
                                    if (givenAnswer === 'Ja' && question.type === 'boolean-quantity') {
                                        const quantity = surveyAnswer.answers[`${question.hash}-quantity`];
                                        question.answers[givenAnswer].push({
                                            name: surveyAnswer.user.name,
                                            quantity
                                        });
                                    } else {
                                        question.answers[givenAnswer].push(surveyAnswer.user.name);
                                    }
                                }
                            }
                        });


                        this.questionGraphs = this.typeObj.survey.questions.map(question => {
                            const item = {
                                question: question.question,
                            } as QuestionGraph;
                            let min = 0;
                            let max = 0;
                            if (['score'].includes(question.type)) {
                                item.areaData = [];
                                item.xAxisTicks = [];
                                const series = [];
                                if (question.answers) {

                                    for (let i = 1; i <= question.limitCount; i++) {
                                        item.xAxisTicks.push(i);
                                        series.push({
                                            name: i,
                                            value: question.answers[i]?.length ?? 0
                                        });
                                        if (max < question.answers[i]?.length) {
                                            max = question.answers[i]?.length;
                                        }
                                    }
                                    item.areaData.push({
                                        name: question.question,
                                        series
                                    });
                                }
                            }
                            if (['boolean-quantity', 'boolean'].includes(question.type)) {
                                item.pieData = [];
                                for (const property in question.answers) {
                                    item.pieData.push({
                                        name: property,
                                        value: question.answers[property]?.length
                                    });
                                    if (max < question.answers[property]?.length) {
                                        max = question.answers[property]?.length;
                                    }
                                }
                            }
                            if (['options'].includes(question.type)) {
                                item.barData = [];
                                if (question.answers) {
                                    question.options.forEach(option => {
                                        item.barData.push({
                                            name: option,
                                            value: question.answers[option]?.length ?? 0
                                        });
                                        if (max < question.answers[option]?.length) {
                                            max = question.answers[option]?.length;
                                        }
                                    });
                                }
                            }

                            item.yAxisTicks = [min];
                            let first = Math.round(max / 4)
                            let last = Math.round((max / 4) * 3)
                            let middle = Math.round(max / 2)
                            if (!item.yAxisTicks.includes(first)) {
                                item.yAxisTicks.push(first);
                            }
                            if (!item.yAxisTicks.includes(middle)) {
                                item.yAxisTicks.push(middle);
                            }
                            if (!item.yAxisTicks.includes(last)) {
                                item.yAxisTicks.push(last);
                            }
                            item.yAxisTicks.push(max);
                            return item;
                        });
                    });
                }));
            }
        }, () => {
        }));
    }

    delete() {
        this.confirmDialogService.confirm('Verwijderen',
            `Weet je zeker dat je deze vragenlijst inclusief antwoorden wilt verwijderen?`, 'Verwijderen', 'Behouden').then(() => {
            this.surveyService.delete(this.typeObj.survey.id).subscribe(() => {
                this.router.navigate([this.typeName, this.typeObj.slug]);
            });
        }, () => {
        });
    }

    startChat(question: SurveyQuestion, answer: string) {
        const users = [...Array.isArray(question.answersUsers) ? question.answersUsers : question.answersUsers[answer] as number[]];
        if (users.indexOf(Storage.getUser()?.id) === -1) {
            users.push(Storage.getUser()?.id);
        }

        const chatData = {
            name: question.question,
            users,
        };
    }
}

export class QuestionGraph {
    question: string;
    yAxisTicks: number[];
    xAxisTicks: number[];
    areaData?: {
        name: string,
        series: {
            value: number,
            name: string
        }[]
    }[];
    barData?: {
        name: string,
        value: number
    }[];
    pieData?: {
        name: string,
        value: number
    }[];
}

export class Answer {
    name: string;
    value: string;
}
