<div class="replies" cdkDropList cdkDropListSortingDisabled [cdkDropListDisabled]="disableDragDrop">
    <div *ngFor="let reply of replies | filter : selectedActivity?.id : 'townprojectphase_activity_id'"
         class="reply"
         cdkDrag
         [class.highlight]="highlightId === reply.id"
         [cdkDragDisabled]="user.group !== 'ADMIN' && user.id !== reply.user_id"
         [cdkDragData]="reply">
        <div class="prefix">
            <div [class.document]="reply.is_document" class="icon"></div>
        </div>
        <div (click)="openReply(reply)" class="item">
            <b>{{reply.name}}</b><br>
            <ng-container *ngIf="!reply.is_document">
                <div [innerHTML]="reply.text | nl2br"></div>
                <br>
            </ng-container>
            {{reply.user?.name}} {{reply.updated_at | date : 'd MMMM yyyy, H:mm'}} uur
        </div>
        <div class="suffix">
            <div class="icon"></div>
            <button (click)="delete(reply)" *ngIf="user.group === 'ADMIN' || user.id === reply.user_id" color="warn" mat-icon-button>
                <mat-icon class="fa fa-trash"></mat-icon>
            </button>
        </div>
    </div>
    <mat-form-field (focusin)="inputActive = true" (focusout)="inputActive = false">
        <div matPrefix></div>
        <mat-label>
            Voeg een reactie toe
            <img src="../../../assets/images/icons/townprojects/arrow_right.svg">
        </mat-label>
        <textarea #autosize="cdkTextareaAutosize"
                  #replyInput
                  (keyup)="enterKey($event)"
                  [formControl]="fcReplyContent"
                  cdkAutosizeMaxRows="5"
                  cdkAutosizeMinRows="1"
                  cdkTextareaAutosize
                  matInput></textarea>
        <div [class.show-save]="fcReplyContent.value" matSuffix>
            <app-document-upload (uploaded)="upload($event)"
                                 [buttonIcon]="true"
                                 [fileTypes]="['application/pdf', 'image/jpeg', 'image/png']"
                                 [town]="town"
                                 currentPath="{{phase?.id}}"
                                 icon="../../assets/images/icons/townprojects/document.svg"
                                 storage="projectreplies"></app-document-upload>
            <button (click)="save($event)" *ngIf="fcReplyContent.value" color="primary" mat-icon-button>
                <img src="../../../assets/images/icons/townprojects/arrow_right.svg">
            </button>
        </div>
    </mat-form-field>
</div>
