import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TownProjectService} from '../services/townproject/town-project.service';
import {TownService} from '../services/town/town.service';
import {Town} from '../town.class';
import {TownProject} from '../town-project';
import {Sort} from '@angular/material/sort';
import {Utils} from '../utils.class';
import {Routenames} from 'app/route-names.enum';
import {SearchService} from '../services/search/search.service';
import {User} from '../user.class';
import {CodaltComponent} from '../codalt.component';
import {Storage} from '../storage.class';
import {Settings} from '../settings.class';

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent extends CodaltComponent implements OnInit, OnDestroy {

    @ViewChild('projectManagerFilterTemplate', {static: true}) projectManagerFilterTemplate;
    projectManagerFilter: number[];
    projectManagers: User[];
    searchValue = '';
    projectFilter = ['regular', 'maintenance'];

    projects: TownProject[];
    towns: Town[];
    Utils = Utils;
    Routenames = Routenames;
    isConsumer = Utils.userIsGroup('CONSUMER');
    displayedColumns = [
        'town',
        'name',
        'type',
        'consumers',
        'manager',
        'phase',
        'workarea_count',
        'latest_activity',
        'actions'
    ];
    currentSort: Sort = {
        active: 'latest_activity',
        direction: 'desc'
    };
    private unfilteredProjects: TownProject[];

    constructor(private projectService: TownProjectService,
                private townService: TownService,
                private searchService: SearchService) {
        super();
    }

    ngOnDestroy(): void {
        this.searchService.searchTemplate = null;
    }

    changeProjectMangerFilter() {
        this.searchAndSort();
    }

    sortProjects(sort?: Sort) {
        if (sort) {
            this.currentSort = sort;
        } else {
            sort = this.currentSort;
        }
        const data = this.projects.slice();
        if (!sort.active || sort.direction === '') {
            this.projects = data;
            return;
        }

        this.projects = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            if (sort.active === 'town') {
                return Utils.compare(this.towns.find(t => t.id === a?.town_id)?.code, this.towns.find(t => t.id === b?.town_id)?.code, isAsc);
            }
            return Utils.compare(a[sort.active], b[sort.active], isAsc);
        });

    }

    ngOnInit() {
        this.townService.getList().then(towns => {
            this.towns = towns;
        });
        this.getProjects(true);
        this.subscriptions.add(this.searchService.change.subscribe(searchValue => {
            this.searchValue = searchValue;
            this.searchAndSort();
        }));
    }

    searchAndSort() {
        this.projects = this.unfilteredProjects.filter(p => {
            return (p.name.toLowerCase().indexOf(this.searchValue) !== -1 ||
                    this.towns.find(t => t.id === p.town_id)?.name.toLowerCase().indexOf(this.searchValue) !== -1 ||
                    ('' + p.manager_user?.name).toLowerCase().indexOf(this.searchValue) !== -1 ||
                    ('' + p.manager_user?.lastname).toLowerCase().indexOf(this.searchValue) !== -1) &&
                (!this.projectManagerFilter?.length || this.projectManagerFilter.indexOf(p.manager_user?.id) !== -1);
        });
        this.sortProjects();
    }

    getProjects(init?: boolean) {
        const allProjectTypes = Settings.projectTypes.map(pt => pt.key);
        const projectType = this.projectFilter.filter(f => allProjectTypes.indexOf(f) !== -1);
        const archive = !!this.projectFilter.find(f => f === 'archive');
        this.projectService.getProjects(false, null, projectType, archive).subscribe((projects) => {
            this.unfilteredProjects = this.projects = projects.data;
            setTimeout(() => {
                this.sortProjects(this.currentSort);
            });
            this.projectManagers = [];
            this.projects.filter(p => !!p.manager_user).map(p => p.manager_user).forEach(user => {
                if (!this.projectManagers.find(u => u.id === user.id)) {
                    this.projectManagers.push(user);
                    if (init && Storage.getUser()?.id === user?.id) {
                        this.projectManagerFilter = [];
                        this.projectManagerFilter.push(user.id);
                    }
                }
            });
            if (Storage.getUser().group !== 'CONSUMER') {
                this.searchService.searchTemplate = this.projectManagerFilterTemplate;
            }
            this.searchAndSort();
        });
    }

}
